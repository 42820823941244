import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import { Phone, Send } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MainAPI from "../APIs/MainAPI";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import SocketContext from "../Contexts/SocketContext";
import AuthContext from "../Contexts/AuthContext";
import Empty from "../Components/Empty";
import Utils from "../Models/Utils";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { props } from "../APIs/api";

function ChatScreen() {

    const {alert, setShowAlert, setWaiting, openAlert} = useContext(AlertContext);
    const {isLoggedIn, loggedUser, cookies} = useContext(AuthContext);
    const {chatInfo, sendMessage, serverMessage} = useContext(SocketContext);

    const navigate = useNavigate();

    const [ message, setMessage ] = useState<string>("");
    const [category, setCategory] = useState<("iphone"|"android"|"other")>("iphone");
    const [allMessages, setAllMessages] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");
    const [attachedFiles, setAttachedFiels] = useState<{index: number, name: string, file: any, extension: string}[]>([]);

    useEffect(() => {
        loadService();
    }, [serverMessage, chatInfo])

    const loadService = async () => {
        // setTimeout(() => {setWaiting(true);}, 1);
        try{

            if(chatInfo){

                let chat_data = {
                    chatId: {
                        type: "text",
                        operator: Operators.IS,
                        value: `${chatInfo.Id}`
                    }
                };
                let service_response = await MainAPI.getAllRelatedFree("message", 1, 100, chat_data);
                if(service_response.Items.length > 0) {
                    setAllMessages(service_response.Items);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 10);
                }else {
                    setAllMessages([{
                        message: "Wellcome back What can we help you today?",
                        boat: 1,
                        id: 0,
                        attachments: [],
                        date: new Date().toISOString()
                    }]);
                }

            }

        } catch(error: any) {
            openAlert(AlertTypes.Error, error.message);
        }
        // setTimeout(() => {setWaiting(false);}, 1);
    }

    const sender = (event: any) => {

        event.preventDefault();

        if(message == "" && attachedFiles.length == 0) {
            return;
        }

        let send_data: any = {
            status: "incoming",
            attachments: attachedFiles,
            message
        };

        if(isLoggedIn || cookies.login_token) {
            send_data.token = cookies.login_token;
        } else if(cookies.chat_id) {
            send_data.chatId = cookies.chat_id;
        }

        setAllMessages(amsg => [...amsg, {
            boat: 0,
            message,
            attachments: attachedFiles,
            date: new Date().toISOString()
        }]);

        sendMessage(send_data);
        setMessage("");
        setAttachedFiels([]);
        setTimeout(() => {
            scrollToBottom();
        }, 10);

    }

    function scrollToBottom() {
        const chatContainer = document.getElementById("chat-container");
        if(chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }

    const addAttachment = (event: any) => {

        if(event.target.files.length == 0) {
            return;
        }

        let fileSize = (event.target.files[0].size / (1024 * 1024)).toFixed(2);
        console.log("file size is ", `${fileSize}MB`);

        if(parseFloat(fileSize) > 2) {
            openAlert(AlertTypes.Error, "No more than 2MB file is allowed!");
            return;
        }

        const reader = new FileReader();
        reader.onload = function(event1: any) {
            let name_arr = event.target.files[0].name.split(".");
            setAttachedFiels(af => [...af, {
                index: af.length,
                name: event.target.files[0].name,
                file: event1.target.result,
                extension: name_arr[name_arr.length - 1]
            }]);
        };
        reader.readAsDataURL(event.target.files[0]);
    
    };

    const removeAttachment = (indx: number) => {
        let temp_af = attachedFiles.filter(af => (af.index != indx));
        setAttachedFiels(af => temp_af);
    };
    
    return (
        <div className="text-white h-100 w-100" style={{display: "flex", flexDirection: "column"}}>
            <div className="w-100 py-2 px-3">
                <a href="tel:+251987609999" className="nuwa-white-btn btn py-2 px-3 text-center w-100" style={{left: "0", transform: "none"}}>
                    <Phone className="me-3"/>
                    Call Us
                </a>
            </div>
            <div className="w-100 h-100" id="chat-container" style={{overflow: "hidden auto"}}>
                {
                    (allMessages.length > 0) ? (

                        <div className="w-100 justify-content-end pt-3" style={{display: "flex", flexDirection: "column", minHeight: "100%", height: "max-content"}}>
                            {
                                allMessages.map(msg => (
                                    (msg.boat == 1) ? (
                                        <div key={msg.id} className="d-flex w-100 justify-content-start px-3 mb-3" style={{height: "max-content"}}>
                                            <div className="d-flex me-2">
                                                <SupportAgentIcon className="nuwa-icon mt-auto" style={{fontSize: "40px"}}/>
                                            </div>
                                            <div className="nuwa-card px-2 pt-2 pb-1 me-auto mb-1" style={{width: "max-content", borderRadius: "13px", borderBottomLeftRadius: 0, backdropFilter: "blure(35px)"}}>
                                                <div>
                                                    {msg.attachments.map((att: any) => (
                                                        <div className="d-flex py-1 mb-1 align-items-center">
                                                            <div className="me-3" style={{width: 'max-content'}}>
                                                                <a style={{textDecoration: "none"}} className="text-white" href={att.id ? `${props.baseURL}file/${att.id}` : att.file} download={att.name}>
                                                                    <InsertDriveFileIcon sx={{fontSize: "30px"}} />
                                                                </a>
                                                            </div>
                                                            <div className="col" style={{fontSize: "12px", wordBreak: "break-word", textWrap: "wrap"}}>{att.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className="card-subtitle text-start mb-2" style={{fontSize: "13px", whiteSpace: "pre-wrap"}}>
                                                    {msg.message}
                                                </p>
                                                <div className="text-light lead" style={{fontSize: "11px"}}>{Utils.convertISOToDate(msg.date)}</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={msg.id} className="d-flex w-100 justify-content-start px-3 mb-3" style={{height: "max-content"}}>
                                            <div className="nuwa-card px-2 pt-2 pb-1 ms-auto mb-1" style={{width: "max-content", borderRadius: "13px", borderBottomRightRadius: 0, backdropFilter: "blure(35px)"}}>
                                                <div>
                                                    {msg.attachments.map((att: any) => (
                                                        <div className="d-flex py-1 mb-1 align-items-center">
                                                            <div className="me-3" style={{width: 'max-content'}}>
                                                                <a
                                                                    style={{textDecoration: "none"}}
                                                                    className="text-white"
                                                                    href={att.id ? `${props.baseURL}file/${att.id}` : att.file }
                                                                    download={att.name}
                                                                >
                                                                    <InsertDriveFileIcon sx={{fontSize: "30px"}} />
                                                                </a>
                                                            </div>
                                                            <div className="col" style={{fontSize: "12px", wordBreak: "break-word", textWrap: "wrap"}}>{att.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className="card-subtitle text-start mb-2" style={{fontSize: "13px", whiteSpace: "pre-wrap"}}>
                                                    {msg.message}
                                                </p>
                                                <div className="text-light text-end lead" style={{fontSize: "11px"}}>{Utils.convertISOToDate(msg.date)}</div>
                                            </div>
                                            <div className="d-flex ms-2">
                                                <PersonIcon className="nuwa-icon mt-auto" style={{fontSize: "40px"}}/>
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                        </div>

                    ) : (
                        <div className="w-100 h-100 justify-content-center px-4" style={{display: "flex", flexDirection: "column"}}>
                            <Empty text="if you need support we are online!" />
                        </div>
                    )
                }

            </div>

            <div className="px-3 mt-3 pb-2">
                <div className="nuwa-card nuwa-search-container w-100 px-3 py-2 d-flex w-100" style={{borderRadius: "30px", backdropFilter: "blure(35px)"}}>
                    <div className="w-100" style={{overflow: "hidden"}}>
                        <div className="w-100" style={{overflow: "auto"}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                {
                                    attachedFiles.map(af => (
                                        <span key={`attachment_${af.index}`} style={{fontSize: "12px"}} className="rounded-5 bg-dark text-light shadow-sm me-3 mb-3 px-2 py-1 d-flex align-items-center">
                                            {
                                                (["png", "jpeg", "jpg", "gif"].includes(af.extension.toLowerCase())) ? (
                                                    <img src={af.file} alt={af.name} style={{width: "30px", height: "auto", marginRight: "10px"}} />
                                                ) : (<InsertDriveFileIcon sx={{ marginRight: "10px", fontSize: 25, color: "white" }} />)
                                            }
                                            {af.name.length > 10 ? af.name.substring(0, 10) : af.name}
                                            <CloseIcon sx={{ marginLeft: "10px", fontSize: 20, color: "red" }} onClick={() => {removeAttachment(af.index)}} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                        <input type="file" style={{display: "none"}} id="file_attachment_input" onChange={addAttachment}/>
                        <div className="d-flex align-items-center">
                            <textarea 
                                className="nuwa-search-input w-100"
                                placeholder="Write here..." 
                                value={message}
                                style={{minHeight: "30px", maxHeight: "90px", height: (message.length < 30 ? "35px" : (message.length < 60 ? "60px" : "90px"))}} 
                                onChange={(event: any) => {if(event.target.value.length < 253){setMessage(event.target.value);}}}
                            />
                            <AttachFileIcon
                                sx={{marginLeft: "5px", fontSize: 25, color: "white"}}
                                onClick={() => {document.getElementById("file_attachment_input")?.click()}}
                            />
                        </div>
                    </div>
                    <Send className="nuwa-icon ms-3 mt-auto mb-2" style={{display: ((message != "" || attachedFiles.length > 0) ? "" : "none"), fontSize: "25px", transform: "rotateZ(-45deg)"}} onClick={sender} />
                </div>
            </div>

        </div>
    );

}

export default ChatScreen;