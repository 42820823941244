import {createContext} from 'react';
import AlertTypes from '../Enums/AlertTypes';

export default createContext<{
    Services: any[],
    Devices: any[]
    Blogs: any[]
    FAQs: any[]
}>({
    Services: [],
    Devices: [],
    Blogs: [],
    FAQs: []
});