import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import StarIcon from '@mui/icons-material/Star';
import NavContext from "../Contexts/NavContext";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Utils from "../Models/Utils";
import OrderStatus from "../Enums/OrderStatus";
import { Authorized, props } from "../APIs/api";

function OrderDetailScreen () {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies, isLoggedIn, loggedUser } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);
    const navigate = useNavigate();
    const params = useParams()

    const localOrderStates = [
        { value: OrderStatus.Requested, label: "Requested" },
        { value: OrderStatus.Approved, label: "Approved" },
        { value: OrderStatus.Received, label: "Phone Given" },
        { value: OrderStatus.Ongoing, label: "Work Ongoing" },
        { value: OrderStatus.Finished, label: "Finished" },
        { value: OrderStatus.Paid, label: "Paid" },
        { value: OrderStatus.Taken, label: "Phone Taken" },
        { value: OrderStatus.Cancelled, label: "Cancelled" },
    ];

    const [order, setOrder] = useState<any>(null);
    const [respairs, setRepairs] = useState<any[]>([]);
    const [stars, setStars] = useState<any[]>([]);
    const [starsPopup, setStarsPopup] = useState<any[]>([]);
    const [ratingPopup, setRatingPopup] = useState<boolean>(false);
    const [paymentPopup, setPaymentPopup] = useState<boolean>(false);
    const [issueWarrantyPopup, setIssueWarrantyPopup] = useState<boolean>(false);
    const [rating, setRating] = useState<number>(0);
    const [warrantyProblem, setWarrantyProblem] = useState<string>("");

    useEffect(() => {

        if(!isLoggedIn) {
            navigate("/home");
            (selectNav && selectNav("home"))
        }else {
            loadOrder();
            loadRepairs();
        }

    }, [])

    useEffect(() => {

        let temp = [];
        let popup_temp  = [];

        for(let i = 0; i < 5; i++) {
            temp.push(((i+1) <= rating) ? (
                <StarIcon className="nuwa-icon" style={{fontSize: "20px"}} />
            ) : (
                <StarBorderIcon className="nuwa-icon" style={{fontSize: "20px"}} />
            ))
            popup_temp.push(((i+1) <= rating) ? (
                <StarIcon className="nuwa-icon" style={{fontSize: "40px"}} onClick={() => {setRating((i+1));}} />
            ) : (
                <StarBorderIcon className="nuwa-icon" style={{fontSize: "40px"}} onClick={() => {setRating((i+1));}} />
            ))
        }

        setStars(temp);
        setStarsPopup(popup_temp);

    }, [rating])

    const loadOrder = async () => {
        setTimeout(() => {setWaiting(true);}, 1);
        try{
            let order_response = await MainAPI.getSingleRelated(cookies.login_token, "order", parseInt(params?.id ?? "0"));
            setOrder(order_response);
            setRating(((order_response && order_response.rating) ? order_response.rating : 0));

        }catch(error: any){
            openAlert(AlertTypes.Error, error.message);
        }
        setTimeout(() => {setWaiting(false);}, 1);
    }

    const loadRepairs = async () => {
        try {
            let reapir_response = await MainAPI.getAll(cookies.login_token, "repair", 1, 10, {
                orderId: {
                    value: parseInt(params?.id ?? "0"),
                    type: "number",
                    operator: Operators.IS
                }
            });
            setRepairs(reapir_response.Items);
        } catch(error: any) {
            openAlert(AlertTypes.Error, error.message);
        }
    }

    const updateOrder = async (rating?: number, status?: string) => {

        setTimeout(() => {setWaiting(true);}, 1);

        try {

            let send_data: any = {
                id: order.id,
                deviceId: order.deviceId,
                serviceId: order.serviceId,
                userId: order.userId,
                date: order.date,
                number: order.number,
                is_paid: order.is_paid,
                state: status ?? order.state
            };
            
            if(rating){
                send_data.rating = rating;
            }
            
            if(status) {
            }

            let reapir_response = await MainAPI.update(cookies.login_token, "order", send_data);

            if(status) {
                setOrder((ord: any) => ({...ord, state: status}));
            }

        } catch(error: any) {
            openAlert(AlertTypes.Error, error.message);
        }

        setTimeout(() => {setWaiting(false);}, 1);

    }

    const issueWarranty = async () => {

        setTimeout(() => {setWaiting(true);}, 1);

        try {

            if(warrantyProblem == ""){
                throw new Error("Problem explanation cannot be empty!");
            }

            let response = await MainAPI.createNew(cookies.login_token, "repair", {
                orderId: order.id,
                description: warrantyProblem
            });

            openAlert(AlertTypes.Success, "Your warranty request has been sent for review. we will contact you as soon as possible.");

        } catch(error: any) {
            openAlert(AlertTypes.Error, error.message);
        }

        setIssueWarrantyPopup(false);
        setTimeout(() => {setWaiting(false);}, 1);

    }

    const sendRating = async () => {

        await updateOrder(rating);
        setRatingPopup(false);

        // try {
        //     let send_data = {

        //     };
        //     let reapir_response = await MainAPI.update(cookies.login_token, "repair", send_data);
        //     setRepairs(reapir_response.Items);
        // }catch(error: any){
        //     openAlert(AlertTypes.Error, error.message);
        // }
    }

    const getState = (state: string) => {
        switch(state) {
            case OrderStatus.Cancelled:
                return "text-danger";
            case OrderStatus.Approved:
                return "text-suuccess";
            case OrderStatus.Closed:
                return "text-success";
            case OrderStatus.Finished:
                return "text-success";
            case OrderStatus.Ongoing:
                return "text-primary";
            case OrderStatus.Paid:
                return "text-primary";
            case OrderStatus.Received:
                return "text-warning";
            case OrderStatus.Requested:
                return "text-primary";
            case OrderStatus.Taken:
                return "text-warning";
        }
    }

    const payAction = async () => {
        try {

            let response = await Authorized(cookies.login_token).bodyRequest("post", "payment/telebir", {order: order.id});
            let obj = JSON.stringify({
                functionName: "js_fun_start_pay",
                params: {
                    rawRequest: response.data.trim(),
                    functionCallBackName: "handleinitDataCallback",
                },
            });

            setPaymentPopup(false);

            if (typeof response.data === undefined || response.data === null) return;

            if (typeof window.consumerapp === undefined || window.consumerapp === null) {
                openAlert(AlertTypes.Info, "This is not opened in app! Please use the telebirr SuperApp for payment!", window.consumerapp);
                console.log("This is not opened in app!", window.consumerapp);
                return;
            }

            window.consumerapp.evaluate(obj);
            // if (window.consumerapp) {
            //     await window.consumerapp.evaluate(obj);
            // }

        } catch(error: any) {
            openAlert(AlertTypes.Error, `error occurred ${error.message}`);
        }

    }

    return (order) && (
        <div className="w-100 h-100" style={{overflow: "auto"}} >
            
            <div className="w-100 px-3 text-white">
                <div className="w-100 d-flex">
                    <img src={`${props.baseURL}file/${order.service.picture}`} className="col-4" alt="phone pic" />
                    <div className="w-100 ps-3">
                        <h4 className="card-title">{order.service.title}</h4>
                        <div className={`card-subtitle ${getState(order.state)}`}>{Utils.getFromArray("value", order.state, "label", localOrderStates)}</div>
                        <span className="card-subtitle text-white" onClick={() => {
                            // if([OrderStatus.Closed, OrderStatus.Finished, OrderStatus.Paid, OrderStatus.Taken].includes(order.state)) {
                            // }
                            setRatingPopup(true);
                        }}>
                            {stars}
                        </span>
                    </div>
                </div>
                <hr className="nuwa-line-hr mb-2" />
                <div className="d-flex justify-content-end">
                    {
                        ([OrderStatus.Taken, OrderStatus.Closed].includes(order.state) && order.warrantyDate && (new Date(order.warrantyDate).getTime() > new Date().getTime())) && (
                            <button className="nuwa-white-btn py-2 px-3 text-center ms-3"
                                style={{fontSize: "14px", left: 0, transform: "none"}}
                                onClick={() => {
                                    setIssueWarrantyPopup(true);
                                }}
                            >
                                Issue Warranty
                            </button>
                        )
                    }
                    {
                        (order.is_paid == "not_paid") && (
                            <button className="nuwa-white-btn py-2 px-3 text-center ms-3"
                                style={{fontSize: "14px", left: 0, transform: "none"}}
                                onClick={() => {
                                    // navigate("/issue_warranty");
                                    // (selectNav && selectNav("order"))
                                    setPaymentPopup(true);
                                }}
                            >
                                Pay Bill
                            </button>
                        )
                    }
                    {
                        (order.state == OrderStatus.Finished) && (
                            <button className="nuwa-white-btn py-2 px-3 text-center ms-3"
                                style={{fontSize: "14px", left: 0, transform: "none"}}
                                onClick={() => {
                                    updateOrder(undefined, OrderStatus.Taken);
                                    // navigate("/issue_warranty");
                                    // (selectNav && selectNav("order"))
                                }}
                            >
                                Phone Received
                            </button>
                        )
                    }
                    {
                        ([OrderStatus.Requested, OrderStatus.Received].includes(order.state)) && (
                            <button className="nuwa-white-btn py-2 px-3 text-center ms-3"
                                style={{fontSize: "14px", left: 0, transform: "none"}}
                                onClick={() => {
                                    updateOrder(undefined, OrderStatus.Cancelled);
                                    // navigate("/issue_warranty");
                                    // (selectNav && selectNav("order"))
                                }}
                            >
                                Cancel
                            </button>
                        )
                    }
                </div>
                
                <hr className="nuwa-line-hr mt-2" />
                <h4 className="card-title mb-3" style={{fontSize: "20px"}}>Order Detail</h4>

                <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                    <span className="card-title">Order Number</span>
                    <span className="card-title text-end">{order.number}</span>
                </div>
                <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                    <span className="card-title">Device Name</span>
                    <span className="card-title text-end">{order.device.name}</span>
                </div>
                <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                    <span className="card-title">Payment State</span>
                    <span className="card-title text-end">{order.is_paid == "paid" ? "Payment Done" : "Not Paid"}</span>
                </div>
                <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-3" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                    <span className="card-title">Warranty Expires</span>
                    <span className={`card-title text-end ${(new Date().getTime() > new Date(order.warrantyDate).getTime()) ? "text-danger" : "text-success"}`}>{Utils.convertISOToDate(order.warrantyDate)}</span>
                </div>

                <h4 className="card-title " style={{fontSize: "20px"}}>Repairs</h4>
                <hr className="nuwa-line-hr" />

                {
                    respairs.map(rpr => (
                        <div key={"repair_" + rpr.id} className="row mb-3" style={{cursor: "pointer"}} onClick={() => {
                            navigate(`/repair_detail/${rpr.id}`);
                            (selectNav && selectNav("order"))
                        }}>

                            <div className="col-5">
                                <div className="card-subtitle" style={{fontSize: "18px"}}>Service</div>
                            </div>
                            <div className="col-7">
                                <h6 className="card-title text-end" style={{fontSize: "18px"}}>{order.service.title}</h6>
                            </div>

                            <div className="col-7">
                                <div className="card-subtitle" style={{fontSize: "18px"}}>Status</div>
                            </div>
                            <div className="col-5">
                                <h6 className={`card-title text-end ${getState(rpr.state)}`} style={{fontSize: "18px"}}>{Utils.getFromArray("value", rpr.state, "label", localOrderStates)}</h6>
                            </div>

                            <div className="col-7">
                                <div className="card-subtitle" style={{fontSize: "15px"}}>Date Start</div>
                            </div>
                            <div className="col-5">
                                <div className="card-subtitle text-end" style={{fontSize: "15px"}}>Date Closed</div>
                            </div>

                            <div className="col-7">
                                <div className="card-subtitle" style={{fontSize: "15px"}}>{Utils.convertISOToDate(order.date)}</div>
                            </div>
                            <div className="col-5">
                                <div className="card-subtitle text-end" style={{fontSize: "15px"}}>{Utils.convertISOToDate(rpr.closingDate)}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                ratingPopup && (
                    <div className="text-white h-100 w-100 px-3 justify-content-center"
                        style={{background: "rgba(0, 0, 0, 0.5)", display: "flex", flexDirection: 'column', position: "absolute", top: 0, left: 0, zIndex: 500}}>

                        <div className="nuwa-card p-3 w-100" style={{borderRadius: "30px", backdropFilter: "blur(35px)"}}>

                            <h4 className="card-title text-center" style={{fontSize: "25px"}}>Rate Our Service</h4>
                            <div className="d-flex justify-content-center my-3">
                                <span className="card-subtitle">
                                    {starsPopup}
                                </span>
                            </div>

                            <div className="d-flex justify-content-between my-3">
                                <button className="nuwa-white-btn py-2 px-3 text-center w-100 me-3" style={{left: "0", transform: "none"}} onClick={() => {setRatingPopup(false)}}>Cancel</button>
                                <button className="nuwa-white-btn py-2 px-3 text-center w-100" style={{left: "0", transform: "none"}} onClick={sendRating}>Submit</button>
                            </div>

                        </div>

                    </div>
                )

            }
            {
            
                paymentPopup && (
                    <div className="text-white h-100 w-100 px-3 justify-content-center"
                        style={{background: "rgba(0, 0, 0, 0.5)", display: "flex", flexDirection: 'column', position: "absolute", top: 0, left: 0, zIndex: 500}}>

                            <div className="w-100">
                                <div className="card-subtitle text-center mb-2">Amount Due</div>
                                <h3 className="card-title text-center mb-2" style={{fontSize: "40px", fontWeight: "bolder"}}>{order.price ?  `${order.price} ETB` : "No Price"}</h3>
                                <div className="nuwa-card p-3 w-100" style={{borderRadius: "30px", backdropFilter: "blur(35px)"}}>

                                    <div className="d-flex mb-3">
                                        <div className="col">
                                            <div className="card-subtitle">Status</div>
                                            <h6 className="card-title" style={{fontSize: "20px"}}>{order.is_paid == "paid" ? "Paid" : "Unapid"}</h6>
                                        </div>
                                        <div className="col">
                                            <div className="card-subtitle text-end">Invoice Date</div>
                                            <h6 className="card-title text-end" style={{fontSize: "20px"}}>{Utils.convertISOToDate(order.date)}</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-5">
                                        <div className="col">
                                            <div className="card-subtitle">Order Number</div>
                                            <h6 className="card-title" style={{fontSize: "20px"}}>{order.number}</h6>
                                        </div>
                                        <div className="col">
                                            <div className="card-subtitle text-end">Date</div>
                                            <h6 className="card-title text-end" style={{fontSize: "20px"}}>{Utils.convertISOToDate((new Date().toISOString()))}</h6>
                                        </div>
                                    </div>

                                    {
                                        (order.is_paid == "not_paid" && order.price) && (
                                            <button className="nuwa-white-btn py-2 px-3 text-center w-75 mb-2" 
                                                onClick={payAction}
                                            >
                                                Pay With Telebirr
                                            </button>
                                        )
                                    }
                                    <button className="nuwa-white-btn py-2 px-3 text-center w-75" onClick={() => {setPaymentPopup(false)}}>Cancel</button>

                                </div>
                            </div>
                    </div>
                )

                
            }
            {
                issueWarrantyPopup && (
                    <div className="text-white h-100 w-100 px-3 pt-3"
                        style={{background: "rgba(0, 0, 0, 1)", overflow: "hidden auto", position: "absolute", top: 0, left: 0, zIndex: 500}}>
                        
                        <h6 className="card-title" style={{fontSize: "22px"}}>Issue Warranty</h6>
                        <p className="lead" style={{fontSize: "15px"}}>
                            Your are trying to request warranty repair for the previous repair 
                            Note: Ensure the bage on the phone is still there otherwise it will not be accepted.
                        </p>
                        <hr className="nuwa-line-hr" />

                        <span className="card-title lead mb-2" style={{fontSize: "15px"}}><b>Problem Explanation</b></span>
                        <div className="nuwa-search-container p-3 mb-3">
                            <textarea className="nuwa-search-input w-100" value={warrantyProblem} onChange={(event: any) => {setWarrantyProblem(event.target.value)}} placeholder="Explane the Problem" />
                        </div>


                        <div className="d-flex">
                            <button className="nuwa-white-btn p-2 text-center w-100 me-3"
                                style={{transform: "none", left: 0}}
                                onClick={() => {
                                    issueWarranty();
                                    // openAlert(AlertTypes.Success, "Your warranty request has been sent for review. we will contact you as soon as possible."); 
                                }}
                            >
                                Issue Warranty
                            </button>
                            <button className="nuwa-white-btn p-2 text-center w-100"
                                style={{transform: "none", left: 0}}
                                onClick={() => {
                                    setIssueWarrantyPopup(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>


                    </div>
                )
            }

        </div>
    );
}

export default OrderDetailScreen;