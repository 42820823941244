import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AuthContext from "../Contexts/AuthContext";
import MainAPI from "../APIs/MainAPI";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import { props } from "../APIs/api";
import NavContext from "../Contexts/NavContext";
import StateContext from "../Contexts/StateContext";
import Empty from "../Components/Empty";

function DeviceDetailScreen() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);
    
    const navigate = useNavigate();
    const params = useParams()

    const [device, setDevice] = useState<any>(null);
    const [related, setRelated] = useState<any[]>([]);
    const [deviceId, setDeviceId] = useState<number>(parseInt(params?.id ?? "0"));
    const {Devices} = useContext(StateContext);

    useEffect(() => {
        loadDevice();
    }, [deviceId, Devices])

    const loadDevice = async () => {
        let found_device = Devices.find(dv => (dv.id == parseInt(params?.id ?? "0")));
        setDevice(found_device ?? null);
        loadRelated(found_device.brand);
    }

    const loadRelated = async (device_brand: string) => {
        setRelated(Devices.filter(dv => (dv.brand == device_brand && dv.id != parseInt(params?.id ?? "0"))));
    }

    return device && (
        <div className="text-white w-100 px-3" style={{overflow: "hidden auto"}}>
            <div className="w-100 d-flex mb-3" style={{borderRadius: "13px", backdropFilter: "blur(35px)"}}>
                <img src={`${props.baseURL}file/${device.picture}`} className="col-7 rounded-3" alt="phone pic" />
                <div className="w-100 ps-3 card-subtitle">
                    {device.specification}
                </div>
            </div>
            <h4 className="card-title">{device.name}</h4>
            <span className="card-subtitle text-center">{device.brand}, {device.model}</span>
            <hr className="nuwa-line-hr" />
            <h5 className="card-title mb-2">Description</h5>
            <p className="w-100 card-subtitle">
                {device.description}
            </p>
            <hr className="nuwa-line-hr" />
            <h5 className="card-title mb-3">Related Devices</h5>
            <div className="w-100" style={{overflow: "auto hidden"}}>
                <div className="d-flex">
                    {
                        (related.length > 0) ? (
                            related.map(rltd => (
                                <img 
                                    src={`${props.baseURL}file/${rltd.picture}`}
                                    className="me-3 rounded-3"
                                    style={{width: "213px", height: "auto"}} alt="phone pic"
                                    onClick={() => {setDeviceId(rltd.id);}}
                                />
                            ))
                        ) : (<Empty text="No Related Device Found!" />)
                    }
                </div>
            </div>
        </div>
    );
}

export default DeviceDetailScreen;