import React, { useContext, useEffect, useState } from "react";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import MenuInfo from "../Components/Onboarding/MenuInfo";
import HomeTrackOrder from "../Components/Onboarding/HomeTrackOrder";
import BottomServices from "../Components/Onboarding/BottomServices";
import SelectService from "../Components/Onboarding/SelectService";
// import AlertTypes from "../Enums/AlertTypes";
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import InfoIcon from '@mui/icons-material/Info';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function OnboardingScreen() {

    const {onboarding, setOnboarding} = useContext(AlertContext);
    const [stage, setStage] = useState<number>(0);
    const [pageIntro, setPageIntro] = useState<any[]>([]);
    // const [info, setInfo] = useState<{path: string, elements: any[]}[]>([]);

    const nextStage = (len: number, val = "continue") => {

        console.log(val, len);
        if(!val) {
            setOnboarding(false);

            // set onboarding finished for the page
            let onboarding_configs: any = JSON.parse(window.localStorage.getItem("onboarding") ?? "{}");
            onboarding_configs[window.location.pathname] = true;
            window.localStorage.setItem("onboarding", JSON.stringify(onboarding_configs))

            return ;

        }

        setStage(stg => (stg+1));

    };

    useEffect(() => {

        let temp_info = [
            {
                path: "/",
                elements: [
                    <MenuInfo next={nextStage} len={3} />,
                    <HomeTrackOrder next={nextStage} len={3} />,
                    <BottomServices next={nextStage} len={3} />
                ]
            },
            {
                path: "/home",
                elements: [
                    <MenuInfo next={nextStage} len={3} />,
                    <HomeTrackOrder next={nextStage} len={3} />,
                    <BottomServices next={nextStage} len={3} />
                ]
            },
            {
                path: "/order",
                elements: [
                    <SelectService next={nextStage} len={1} />,
                ]
            },
        ];

        // setInfo(temp_info);

        let intros = temp_info.find(pi => pi.path == window.location.pathname);

        if(intros) {
            setPageIntro(intros.elements);
        } else {
            setOnboarding(false);
        }

    }, []);

    useEffect(() => {

        if(stage > 0 && stage > (pageIntro.length - 1)) {

            setOnboarding(false);
    
            // set onboarding finished for the page
            let onboarding_configs: any = JSON.parse(window.localStorage.getItem("onboarding") ?? "{}");
            onboarding_configs[window.location.pathname] = true;
            window.localStorage.setItem("onboarding", JSON.stringify(onboarding_configs));

        }

    }, [stage, pageIntro]);

    // const navigate = useNavigate();

    return (
        <div className="text-light h-100 bg-transparent" style={{width: "100vw", position: "fixed", top: 0, zIndex: 1020}}>
            {pageIntro[stage]}
        </div>
    );
}

export default OnboardingScreen;