import React, { useContext, useEffect, useState } from "react";
import BottomNavBar from "../Components/BottomNavbar";
import TopNavBar from "../Components/TopNavbar";
import { Outlet, useLocation } from "react-router-dom";
import {isMobile, isTablet, isDesktop} from 'react-device-detect';
import SideNavBar from "../Components/SideNavbar";
import NavContext from "../Contexts/NavContext";
import AlertContext from "../Contexts/AlertContext";

function MainScreen() {

    const { selectedNav } = useContext(NavContext);
    const { onboarding, setOnboarding } = useContext(AlertContext);
    const [path, setPath] = useState<string>("/");

    const location = useLocation();

    useEffect(() => {

        setPath(location.pathname);

        let onboarding_configs: any = JSON.parse(window.localStorage.getItem("onboarding") ?? "{}");

        if(!onboarding_configs[window.location.pathname]) {
            setOnboarding(true);
        }

    }, [location])

    return (
        <div className="d-flex w-100" style={{height: "100%", position: "relative"}}>
            {isDesktop && (
                <div className="h-100" style={{width: "25%"}}>
                    <SideNavBar />
                </div>
            )}
            <div className="nuwa-screen-bg" style={{width: isMobile ? "100%" : "75%", position: "relative"}} >
                <div className="w-100 px-3 py-2" style={{height: "max-content"}}>
                    <TopNavBar />
                </div>
                <div className="w-100" style={{height: "100%", position: "relative", overflow: "auto"}} >
                    <Outlet />
                </div>
                {(isMobile && path != "/chat") && (
                    <div className="w-100 text-white px-3 py-2" style={{height: "max-content"}}>
                        <BottomNavBar />
                    </div>
                )}
            </div>
        </div>
    );
}
//display: (selectedNav?.id != "chat" ? "block" : "none"), 
export default MainScreen;