import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import NavContext from "../Contexts/NavContext";
import { Login } from "../APIs/AuthAPI";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import { normal } from "../APIs/api";

function TrackOrderScreen() {

    const { openAlert, setWaiting} = useContext(AlertContext);
    const { setLoggedUser, setLoggedIn, setCookie } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);

    const [fields, setFields] = useState<{ orderNumber: string, password: string }>({
        orderNumber: "",
        password: ""
    });

    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const fieldSetter = (event: any) => {
        setFields({ ...fields, [event.target.name]: event.target.value });
    }

    const submitForm = async (event: any) => {
        event.preventDefault();

        setTimeout(() => { setWaiting(true) }, 1);
        try {

            if(fields.orderNumber == "" || fields.password == "") {
                throw new Error("please fill all required fields first!");
            }

            let response = await normal().bodyRequest("post", "auth/login_with_order", fields);

            setLoggedUser(response.user);
            setCookie("login_token", response.user.Token, { path: "/", secure: true, maxAge: 604800 });
            setLoggedIn(true);

            setWaiting(false);
            // openAlert(AlertTypes.Success, "info");
            setTimeout(() => {
                navigate(`/order_detail/${response.order}`);
                (selectNav && selectNav("order"))
            }, 10);

        } catch (error: any) {
            setWaiting(false);
            openAlert(AlertTypes.Error, error.message);
        }
        setTimeout(() => { setWaiting(false) }, 1);

    }

    return (
        <div className="text-white h-100 w-100 px-3 justify-content-center" style={{display: "flex", flexDirection: 'column'}}>
            
            <div className="w-100">
                
                <div className="d-flex w-100 mb-3 justify-content-center">
                    <img src="./images/logo_white.svg" alt="logo" style={{width: "207px", height: "auto"}} />
                </div>
                <div className="px-3 mb-5 w-100">
                    <span className="card-title lead"><b>Order Number</b></span>
                    <div className="nuwa-search-container p-2 mb-3">
                        <input type="text" name="orderNumber" className="nuwa-search-input w-100" placeholder="Order Number" onChange={fieldSetter} />
                    </div>
                    <span className="card-title lead"><b>Password</b></span>
                    <div className="nuwa-search-container p-2">
                        <input type="password" name="password" className="nuwa-search-input w-100" placeholder="User Password" onChange={fieldSetter} />
                    </div>
                </div>
                <button
                    className="nuwa-white-btn py-2 px-3 text-center w-75 mb-2"
                    onClick={submitForm}
                >View</button>

            </div>

        </div>
    );
}

export default TrackOrderScreen;