import React, { useContext, useEffect, useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import NavContext from "../Contexts/NavContext";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../Contexts/AuthContext";


function BottomNavBar() {

    const { items, selectNav } = useContext(NavContext);
    const { cookies, isLoggedIn, loggedUser } = useContext(AuthContext);
    const [path, setPath] = useState<string>("/");
    
    const navigate = useNavigate();
    const location = useLocation();
    // const [navItems, setNavItems] = useState<{icon: any, id: string, selected: boolean}[]>([]);

    useEffect(() => {

        setPath(location.pathname);

    }, [location])

    return (
        <div className="d-flex nuwa-bottom-nav">
            {
                items.map(itm => (
                    <div className="col p-0 h-100" key={itm.id}>
                        <div 
                            className={`nuwa-bottom-nav-item ${itm.selected ? "item_selected" : ""} ab_center`} 
                            onClick={() => {
                                itm.onSelect();
                                navigate(`/${itm.id}`);
                            }}
                        >
                            <itm.icon className="nuwa-icon ab_center" />
                        </div>
                    </div>
                ))
            }
            
            {(isLoggedIn && loggedUser) && (
                <div className="col p-0 h-100">
                    <div className="nuwa-bottom-nav-item ab_center" onClick={() => {
                            (selectNav && (selectNav("account")));
                            navigate(`/account`);
                        }}
                    >
                        <img src="./images/user_avatar_white.png" style={{width: "100%", height: "100%"}} alt="user" />
                    </div>
                </div>
            )}
            {/* <div className="col p-0 h-100">
                <div className="nuwa-bottom-nav-item ab_center">
                    <HomeIcon className="nuwa-icon ab_center" />
                </div>
            </div>
            <div className="col p-0 h-100">
                <div className="nuwa-bottom-nav-item ab_center">
                    <HomeIcon className="nuwa-icon ab_center" />
                </div>
            </div>
            <div className="col p-0 h-100">
                <div className="nuwa-bottom-nav-item ab_center">
                    <HomeIcon className="nuwa-icon ab_center" />
                </div>
            </div> */}
        </div>
    );
}

export default BottomNavBar;