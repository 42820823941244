import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";

function AboutUsScreen() {

    const {alert, setShowAlert} = useContext(AlertContext);
    const navigate = useNavigate();

    return (
        <div className="text-white w-100 px-3" >            
        
            <h4 className="card-title text-center" style={{fontSize: "20px"}}>About Nuwa Mobile Clinic</h4>
            <hr className="nuwa-line-hr" />

            <p className="card-subtitle" style={{fontSize: "15px"}}>
                NUWA Mobile Phone Repair Clinic is a customer-focused business located in Bole, Addis Ababa. We specialize in providing high-quality, 
                efficient, and affordable repairs for smartphones, tablets, and other mobile devices. Our team of certified technicians utilizes 
                top-tier parts and offers warranties on repairs, ensuring customer satisfaction. This proposal outlines our business model and growth strategy, 
                seeking collaboration to further establish and expand NUWA's presence in Bole.

                NUWA prioritizes convenience and exceptional service, setting ourselves apart from competitors. We offer a welcoming environment in our 
                Bole location, eliminating travel hassles for customers who can visit us directly. Our certified technicians possess extensive experience 
                repairing various mobile device brands and models. We differentiate ourselves by using high-quality parts and backing our repairs with 
                warranties for added peace of mind. Additionally, we maintain transparent pricing so customers know exactly what to expect.
            </p>

        </div>
    );
}

export default AboutUsScreen;