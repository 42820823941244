import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import { Send } from "@mui/icons-material";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import NavContext from "../Contexts/NavContext";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import OrderStatus from "../Enums/OrderStatus";
import Utils from "../Models/Utils";
import Empty from "../Components/Empty";

function RepairDetailScreen() {

    const {selectNav} = useContext(NavContext);
    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies, isLoggedIn, loggedUser } = useContext(AuthContext);
    
    const navigate = useNavigate();
    const params = useParams()

    const localOrderStates = [
        { value: OrderStatus.Requested, label: "Requested" },
        { value: OrderStatus.Approved, label: "Approved" },
        { value: OrderStatus.Received, label: "Phone Received" },
        { value: OrderStatus.Ongoing, label: "Work Ongoing" },
        { value: OrderStatus.Finished, label: "Finished" },
        { value: OrderStatus.Paid, label: "Paid" },
        { value: OrderStatus.Taken, label: "Phone Taken" },
        { value: OrderStatus.Cancelled, label: "Cancelled" },
    ];

    const [repair, setRepair] = useState<any>(null);
    const [comments, setComments] = useState<any[]>([]);
    const [commentInput, setCommentInput] = useState<string>("");


    useEffect(() => {
        if(isLoggedIn) {
            loadRepair();
            loadComments();
        }else {
            openAlert(AlertTypes.Warning, "You are currently in Guest mode. You have to login or order a service to see account information!")
            navigate(`/home`);
            (selectNav && selectNav("home"));
        }
    }, [])

    const loadRepair = async () => {
        setTimeout(() => {setWaiting(true);}, 1);
        try{
            let repair_response = await MainAPI.getSingleRelated(cookies.login_token, "repair", parseInt(params?.id ?? "0"));
            setRepair(repair_response);
        }catch(error: any){
            openAlert(AlertTypes.Error, error.message);
        }
        setTimeout(() => {setWaiting(false);}, 1);
    }

    const loadComments = async () => {
        setTimeout(() => {setWaiting(true);}, 1);
        try{
            let repair_response = await MainAPI.getAllRelated(cookies.login_token, "feedback", 1, 100, {
                repairId: {
                    type: "number",
                    operator: Operators.IS,
                    value: parseInt(params?.id ?? "0"),
                }
            });
            setComments(repair_response.Items);
        }catch(error: any){
            openAlert(AlertTypes.Error, error.message);
        }
        setTimeout(() => {setWaiting(false);}, 1);
    }

    const addComment = async (event: any) => {

        event.preventDefault();
        setTimeout(() => {setWaiting(true);}, 1);
        try{
            let comment_response = await MainAPI.createNew(cookies.login_token, "feedback", {
                body: commentInput,
                repairId: repair.id
            });
            console.log(comment_response);
            comment_response.data.user = {
                name: loggedUser.FullName
            };
            setComments(cmts => [...cmts, comment_response.data]);
        }catch(error: any){
            openAlert(AlertTypes.Error, error.message);
        }
        setCommentInput("");
        setTimeout(() => {setWaiting(false);}, 1);

    }
    return repair && (
        <div className="text-white w-100 px-3" >

            <h4 className="card-title " style={{fontSize: "20px"}}>Problem</h4>
            <div className="card-subtitle" style={{fontSize: "15px"}}>
                {repair.description}
            </div>
            {/* <hr className="nuwa-line-hr mb-0" />
            <div className="d-flex justify-content-end py-2">
                <button className="nuwa-white-btn py-2 px-3 text-center ms-3" style={{fontSize: "14px", left: 0, transform: "none"}} >Confirm Repair</button>
            </div> */}
            <hr className="nuwa-line-hr" />
            <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                <span className="card-title">Order Number</span>
                <span className="card-title text-end">{repair.order.number}</span>
            </div>
            <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                <span className="card-title">Status</span>
                <span className="card-title text-end text-white">{Utils.getFromArray("value", repair.state, "label", localOrderStates)}</span>
            </div>
            <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                <span className="card-title">Order Date</span>
                <span className="card-title text-end">{Utils.convertISOToDate(repair.date)}</span>
            </div>
            <div className="nuwa-card d-flex justify-content-between py-2 px-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                <span className="card-title">Delivery Date</span>
                <span className="card-title text-end">{repair.closingDate ? Utils.convertISOToDate(repair.closingDate) : "Not Set"}</span>
            </div>
            
        
            <h4 className="card-title" style={{fontSize: "20px"}}>Comments</h4>
            <hr className="nuwa-line-hr" />

            {
                (comments.length > 0) ? (
                    comments.map(cmnt => (
                        <div className="nuwa-card p-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                            <h4 className="card-title mb-0" style={{fontSize: "20px"}}>{cmnt.user.name}</h4>
                            <span className="card-subtitle" style={{fontSize: "11px"}}>{Utils.convertISOToDate(cmnt.date)}</span>
                            <p className="card-subtitle" style={{fontSize: "13px"}}>
                                {cmnt.body}
                            </p>
                        </div>
                    ))
                ) : (<Empty text="No Feedbacks" />) 
            }

            <div className="nuwa-card nuwa-search-container w-100 px-3 mt-3 py-2 d-flex w-100" style={{borderRadius: "30px", backdropFilter: "blure(35px)"}}>
                <textarea 
                    className="nuwa-search-input w-100"
                    placeholder="Write your feedback here..."
                    style={{minHeight: "30px", maxHeight: "90px", height: "max-content"}}
                    value={commentInput}
                    onChange={(event: any) => {setCommentInput(cmt => event.target.value)}}
                />
                <Send className="nuwa-icon mb-auto ms-3 mt-auto mb-0" style={{fontSize: "25px", transform: "rotateZ(-45deg)"}} onClick={addComment}/>
            </div>

        </div>
    );
}

export default RepairDetailScreen;