import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import SearchForm from "../Components/SearchForm";
import DeviceSelector from "../Components/DeviceSelector";
import AlertTypes from "../Enums/AlertTypes";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import Operators from "../Enums/Operators";
import StateContext from "../Contexts/StateContext";

function DeviceScreen() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const {Devices} = useContext(StateContext);
    const { cookies } = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams()
    
    const [category, setCategory] = useState<("iphone"|"android"|"other")>("iphone");
    const [devices, setDevices] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");


    useEffect(() => {
        loadService();
    }, [category, searchWord])

    const loadService = async () => {
        // setTimeout(() => {setWaiting(true);}, 1);
        setDevices(Devices.filter(dv => (dv.brand == category && dv.name.includes(searchWord))))
        // setTimeout(() => {setWaiting(false);}, 1);
    }

    return (
        <div className="text-white h-100 w-100" style={{display: "flex", flexDirection: 'column'}}>
            
            <div className="d-flex w-100 justify-content-around mb-3">
                <div className={`py-2 mx-auto ${(category == "iphone" ? "border-bottom" : "")}`}>
                    <div className="nuwa-service-big-card p-3" style={{width: "110px", height: "113px"}} onClick={() => {setCategory("iphone")}}>
                        <div className="d-flex">
                            <PhoneIphoneIcon className="nuwa-icon mx-auto" style={{fontSize: "50px"}}/>
                        </div>
                        <h6 className="card-title text-center">I-Phone</h6>
                    </div>
                </div>
                <div className={`py-2 mx-auto ${(category == "android" ? "border-bottom" : "")}`}>
                    <div className="nuwa-service-big-card py-3 px-2" onClick={() => {setCategory("android")}}>
                        <div className="d-flex">
                            <PhoneAndroidIcon className="nuwa-icon mx-auto" style={{fontSize: "50px"}}/>
                        </div>
                        <h6 className="card-title text-center">Android</h6>
                    </div>
                </div>
            </div>

            <div className="px-3 mb-3">
                <SearchForm onSubmit={(word: string) => {setSearchWord(word)}} />
            </div>
            <DeviceSelector list={devices} type="view"/>

        </div>
    );
}

export default DeviceScreen;