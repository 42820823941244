import React from "react";
import RuleFolderIcon from '@mui/icons-material/RuleFolder';

function Empty({text}: {text?: string}) {

    return (
        <div className="d-flex justify-content-center align-item-center w-100 p-2">
            <div className="nuwa-card p-3 w-100" style={{
                borderRadius: "20px",
                backdropFilter: "blur(35px)"
            }}>
                <RuleFolderIcon sx={{fontSize: "30px"}} className="me-3" />
                <span className="card-title text-center mb-2">{text ?? "No Data Found"}</span>
            </div>
        </div>
    );
}

export default Empty;