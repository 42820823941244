import React, { useContext } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

function HomeTrackOrder({ next, len }: { next: any, len: number }) {

    // const {alert, setShowAlert} = useContext(AlertContext);
    // const navigate = useNavigate();

    return (
        <div className="bg-transparent w-100 h-100" style={{ position: "fixed", top: 0}}>
            <div className="rounded-circle bg-transparent intro_pointing" style={{
                width: "90px",
                height: "90px",
                left: "35%",
                top: "57%",
            }}/>
            <div className="rounded-circle bg-transparent" style={{
                width: "90px",
                height: "90px",
                border: "solid 230px rgba(255, 255, 255, 0.9)",
                boxSizing: "content-box",
                position: "absolute",
                left: "35%",
                top: "57%",
                transform: "translateX(-50%) translateY(-50%)"
            }}>
                <div className="bg-transparent p-3" style={{
                    width: "250px",
                    position: "absolute",
                    left: "20%",
                    top: "110%"
                }}>
                    <p className="text-dark mb-1" style={{fontSize: "11px", fontWeight: "bolder"}} >
                        Tracking your Repair order
                    </p>
                    <p className="text-dark lead mb-3" style={{fontSize: "11px"}}>
                        Tap on "Track Repair" and check your order progress and take necessary actions.
                    </p>

                    <div className="d-flex justify-content-between">
                        <button className="btn btn-sm btn-danger" onClick={() => {next(len, null);}}>
                            Exit Tour
                            <CloseIcon sx={{fontSize: "15px", marginLeft: "10px"}} />
                        </button>
                        <button className="btn btn-sm btn-dark" onClick={() => {next(len);}}>
                            Next
                            <ArrowForwardIosIcon sx={{fontSize: "15px", marginLeft: "10px"}} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeTrackOrder;