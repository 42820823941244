import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


function SearchForm({onSubmit}: {onSubmit: (searchWord: string) => void}) {

    const [word, setWord] = useState<string>("");
    const [clear, setClear] = useState<boolean>(false);

    return (
        <div className="w-100 d-flex nuwa-search-container" style={{padding: "10px", paddingRight: "5px"}}>
            <input 
                type="text"
                className="nuwa-search-input w-100"
                value={word}
                onChange={(event) => { setWord(event.target.value) }}
                placeholder="Search with keyword"
                onFocus={() => { setClear(false); }}
                onBlur={() => { setTimeout(() => {setClear(word == "");}, 10) }}
            />
            <button className="nuwa-btn" onClick={(event: any) => {

                event.preventDefault();
                if(clear){
                    setWord("");
                    onSubmit("");
                    setClear(false);
                }else {
                    onSubmit(word);
                }

            }}>
                { (!clear) ? ( <SearchIcon /> ) : ( <CloseIcon /> ) }
            </button>
        </div>
    );

}

export default SearchForm;