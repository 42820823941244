import React, { useContext, useState } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AlertTypes from "../Enums/AlertTypes";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import DeviceList from "./DeviceList";
import SearchForm from "../Components/SearchForm";
import DeviceSelector from "../Components/DeviceSelector";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NavContext from "../Contexts/NavContext";

function RateOrderScreen() {

    const {alert, setShowAlert} = useContext(AlertContext);
    const {selectNav} = useContext(NavContext);
    const navigate = useNavigate();
    const [rate, setRate] = useState<number>(3);

    let stars = [];

    for(let i = 0; i < 5; i++){
        stars.push(((i+1) <= rate) ? (
            <StarIcon className="nuwa-icon" style={{fontSize: "40px"}} onClick={() => {setRate((i+1));}}/>
        ) : (
            <StarBorderIcon className="nuwa-icon" style={{fontSize: "40px"}} onClick={() => {setRate((i+1));}}/>
        ))
    }

    return (
        <div className="text-white h-100 w-100 px-3 justify-content-center" style={{display: "flex", flexDirection: 'column'}}>
            
            <div className="nuwa-card p-3 w-100" style={{borderRadius: "30px", backdropFilter: "blur(35px)"}}>

                <h4 className="card-title text-center" style={{fontSize: "25px"}}>Rate Our Service</h4>
                <div className="d-flex justify-content-center my-3">
                    <span className="card-subtitle">
                        {stars}
                    </span>
                </div>

                <button className="nuwa-white-btn py-2 px-3 text-center w-75 mb-2" onClick={() => {
                    navigate("/order_detail");
                    (selectNav && selectNav("order"))
                }}>Submit</button>

            </div>

        </div>
    );
}

export default RateOrderScreen;