import React, { useContext, useEffect, useState } from "react";
import AlertContext from "../Contexts/AlertContext";
import NavContext from "../Contexts/NavContext";
import AlertTypes from "../Enums/AlertTypes";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Contexts/AuthContext";
import { authFileRequest, fileRequest } from "../APIs/api";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function OrderForm() {

    const {alert, setShowAlert, openAlert, currentOrder} = useContext(AlertContext);
    const { isLoggedIn, loggedUser, cookies } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();

    const [inputs, setInputs] = useState<any>({
        imei: "",
        problem: "",
        phone_image: null,
        full_name: "",
        phone: ""
    })

    useEffect(() => {

        if(!currentOrder.service || !currentOrder.device) {
            navigate("/home"); (selectNav && selectNav("device"));
        } else {

        }
    }, [])

    const submitOrder = async () => {

        try{
            if(inputs.imei == "" || inputs.problem == "") {
                openAlert(AlertTypes.Success, "please make sure you fill the required fields!"); 
            }

            let req_data: any = {
                serviceId: currentOrder.service.id,
                deviceId: currentOrder.device.id,
                imei: inputs.imei,
                problemExp: inputs.problem
            };
            let order_number = "";
            let response: any = null;
            
            if(inputs.phone_image) {
                req_data.attachmentName = "user phone image";
                req_data.attachmentFile = inputs.phone_image;
            }

            if(isLoggedIn){
                response = await authFileRequest(cookies.login_token, "post", "crud/createOrder_Repair_User_Attachment", req_data)
            }else {

                req_data.fullName = inputs.full_name;
                req_data.phone = inputs.phone;
    
                response = await fileRequest("post", "crud/createOrder_Repair_User_Attachment", req_data);

            }
    
            openAlert(AlertTypes.Success, (<>
                Your repair order request has been sent for review. we will contact you as soon as possible. Order number {response.orderId} 
                <ContentCopyIcon
                    sx={{fontSize: 20}}
                    onClick={async () => {
                        navigator.clipboard.writeText(response.orderId.toString()).then(() => {
                            console.log('Text copied to clipboard:', response.orderId);
                        }).catch((error) => {
                            console.error('Error copying text:');
                        });
                    }}
                /> 
            </>));
            navigate("/home");
            selectNav && selectNav("home");

        } catch(error: any){
            openAlert(AlertTypes.Error, error.message); 
        }
    }

    const inputChange = (event: any) => {
        setInputs((npt: any) => ({...npt, [event.target.name]: (event.target.name == "phone_image" ? event.target.files[0] : event.target.value)}))
    }

    return (currentOrder.service && currentOrder.device) && (
        <div className="w-100 text-white px-3">
            <h4 className="card-title">{currentOrder.service.title}</h4>
            <p className="card-subtitle">
                fill the form accordingly. not that there are required and optional field so make sure you fill all the required fields.
            </p>
            <hr className="nuwa-line-hr" />
            <span className="card-title lead"><b>IMEI Number</b></span>
            <div className="nuwa-search-container mb-3" style={{padding: "10px", paddingRight: "5px"}}>
                <input type="text" value={inputs.imei} name="imei" onChange={inputChange} className="nuwa-search-input w-100" placeholder="IMEI Number of Mobile" />
            </div>
            <span className="card-title lead"><b><span className="text-danger">*</span>Problem Explanation</b></span>
            <div className="nuwa-search-container mb-3" style={{padding: "10px", paddingRight: "5px"}}>
                <textarea value={inputs.problem} name="problem" onChange={inputChange} className="nuwa-search-input w-100" placeholder="Explane the Problem" />
            </div>
            <span className="card-title lead"><b> Phone Image</b></span>
            <div className="nuwa-search-container mb-3" style={{padding: "10px", paddingRight: "5px"}}>
                <input type="file" name="phone_image" onChange={inputChange} className="nuwa-search-input w-100" placeholder="Phone Image" />
            </div>

            {
                (!isLoggedIn) && (
                    <>
                        <h4 className="card-title mt-5">User Information</h4>
                        <hr className="nuwa-line-hr" />

                        <span className="card-title lead"><b><span className="text-danger">*</span> Full Name</b></span>
                        <div className="nuwa-search-container mb-3" style={{padding: "10px", paddingRight: "5px"}}>
                            <input type="text" value={inputs.full_name} name="full_name" onChange={inputChange} className="nuwa-search-input w-100" placeholder="Customer Full Name" />
                        </div>

                        <span className="card-title lead"><b><span className="text-danger">*</span> Phone Number</b></span>
                        <div className="d-flex nuwa-search-container mb-3" style={{padding: "10px", paddingRight: "5px"}}>
                            <span className="card-subtitle me-2">+251</span>
                            <input type="text" value={inputs.phone} name="phone" onChange={inputChange} className="nuwa-search-input w-100" placeholder="Phone Number to Contact You" />
                        </div>
                    </>
                )
            }

            <button className="nuwa-white-btn py-2 px-2 text-center w-75"
                onClick={() => {
                    submitOrder();
                }}
            >
                Order Service
            </button>
        </div>
    );
}

export default OrderForm;