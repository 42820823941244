import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AlertTypes from "../Enums/AlertTypes";
import NavContext from "../Contexts/NavContext";

function PaymentScreen() {

    const {alert, setShowAlert, openAlert} = useContext(AlertContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();

    return (
        <div className="text-white h-100 w-100 px-3 justify-content-center" style={{display: "flex", flexDirection: 'column'}}>
            
            <div className="w-100">
                <div className="card-subtitle text-center mb-2">Amount Due</div>
                <h3 className="card-title text-center mb-2" style={{fontSize: "40px", fontWeight: "bolder"}}>1985.50 ETB</h3>
                <div className="nuwa-card p-3 w-100" style={{borderRadius: "30px", backdropFilter: "blur(35px)"}}>

                    <div className="d-flex mb-3">
                        <div className="col">
                            <div className="card-subtitle">Status</div>
                            <h6 className="card-title" style={{fontSize: "20px"}}>Unapid</h6>
                        </div>
                        <div className="col">
                            <div className="card-subtitle text-end">Invoice Date</div>
                            <h6 className="card-title text-end" style={{fontSize: "20px"}}>Dec 10, 2023</h6>
                        </div>
                    </div>
                    <div className="d-flex mb-5">
                        <div className="col">
                            <div className="card-subtitle">Order Number</div>
                            <h6 className="card-title" style={{fontSize: "20px"}}>K72348F8J2</h6>
                        </div>
                        <div className="col">
                            <div className="card-subtitle text-end">Date</div>
                            <h6 className="card-title text-end" style={{fontSize: "20px"}}>Dec 10, 2023</h6>
                        </div>
                    </div>

                    <button className="nuwa-white-btn py-2 px-3 text-center w-75 mb-2" 
                        onClick={() => {
                            openAlert(AlertTypes.Success, "You have paid your invoice successfully!"); 
                            navigate("/order_detail")
                        }}
                    >
                        Pay With Telebirr
                    </button>
                    <button className="nuwa-white-btn py-2 px-3 text-center w-75" onClick={() => {navigate("/order_detail")}}>Cancel</button>

                </div>
            </div>

        </div>
    );
}

export default PaymentScreen;