import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import SearchForm from "../Components/SearchForm";
import DeviceSelector from "../Components/DeviceSelector";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import StateContext from "../Contexts/StateContext";

function DeviceListScreen() {

    const {alert, setShowAlert, openAlert, setWaiting, updateCurrentOrder, currentOrder} = useContext(AlertContext);
    const {Devices} = useContext(StateContext);
    const { cookies } = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams()
    
    const [category, setCategory] = useState<("iphone"|"android"|"other")>("iphone");
    const [devices, setDevices] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");
    const [deviceInfo, setDeviceInfo] = useState<any>({
        name: "",
        model: "",
        brand: "",
        picture: 1,
        description: "customer added device",
        specification: "customer added device",
    });


    useEffect(() => {
        loadService();
    }, [category, searchWord])

    // useEffect(() => {
    //     searchService();
    // }, [searchWord])

    const loadService = async () => {
        setDevices(Devices.filter(dv => (dv.brand == category && dv.name.includes(searchWord))))
        // setTimeout(() => {setWaiting(true);}, 1);
        // try{
        //     let service_response = await MainAPI.getAllFree("device", 1, 100, {
        //         brand: {
        //             type: "text",
        //             operator: Operators.IS,
        //             value: category
        //         },
        //         name: {
        //             type: "text",
        //             operator: Operators.CONTAINS,
        //             value: searchWord
        //         }
        //     });
        //     setDevices(service_response.Items);
        // }catch(error: any){
        //     openAlert(AlertTypes.Error, error.message);
        // }
        // setTimeout(() => {setWaiting(false);}, 1);
    }

    const searchService = async () => {
        // setTimeout(() => {setWaiting(true);}, 1);
        try{

            let service_response = await MainAPI.getAllFree("device", 1, 100, {
                name: {
                    type: "text",
                    operator: Operators.CONTAINS,
                    value: searchWord
                }
            });
            setDevices(service_response.Items);

        } catch(error: any) {
            openAlert(AlertTypes.Error, error.message);
        }
        // setTimeout(() => {setWaiting(false);}, 1);
    }

    const fieldSetter = (event: any) => {
        setDeviceInfo({ ...deviceInfo, [event.target.name]: event.target.value });
    }

    const addDevice = async () => {

        try {

            let device_response = await MainAPI.createNewFree("device", deviceInfo);
            // setDeviceInfo(df => ({...df, id}));
            updateCurrentOrder({...currentOrder, device: {...deviceInfo, id: device_response.data.id}});
            navigate("/order_form");

        } catch(error: any) {
            openAlert(AlertTypes.Error, error.message);
        }

    }

    return (
        <div className="text-white h-100 w-100" style={{display: "flex", flexDirection: 'column'}}>
            <div className="px-3">
                <h4 className="card-title" style={{fontSize: "25px"}}>{currentOrder.service.title}</h4>
                <p className="card-subtitle w-100">
                    Choose your device brand and model.
                </p>
            </div>
            <div className="d-flex w-100 justify-content-around mb-3">

                <div className={`py-2 mx-auto ${(category == "iphone" ? "border-bottom" : "")}`}>
                    <div className="nuwa-service-big-card p-3 mx-auto"  onClick={() => {setCategory("iphone")}}>
                        <div className="d-flex">
                            <PhoneIphoneIcon className="nuwa-icon mx-auto" style={{fontSize: "50px"}}/>
                        </div>
                        <h6 className="card-title text-center">I-Phone</h6>
                    </div>
                </div>
                <div className={`py-2 mx-auto ${(category == "android" ? "border-bottom" : "")}`}>
                    <div className="nuwa-service-big-card p-3 mx-auto"  onClick={() => {setCategory("android")}}>
                        <div className="d-flex">
                            <PhoneAndroidIcon className="nuwa-icon mx-auto" style={{fontSize: "50px"}}/>
                        </div>
                        <h6 className="card-title text-center">Android</h6>
                    </div>
                </div>
                <div className={`py-2 mx-auto ${(category == "other" ? "border-bottom" : "")}`}>
                    <div className="nuwa-service-big-card p-3 mx-auto"  onClick={() => {setCategory("other")}}>
                        <div className="d-flex">
                            <StayCurrentPortraitIcon className="nuwa-icon mx-auto" style={{fontSize: "50px"}}/>
                        </div>
                        <h6 className="card-title text-center">Others</h6>
                    </div>
                </div>
            </div>

            {
                (category == "other") ? (

                    <div className="h-100 w-100 px-3" style={{overflow: "hidden auto"}}>

                        <span className="card-title lead"><b>Device Name</b></span>
                        <div className="nuwa-search-container mb-3" style={{padding: "10px"}}>
                            <input
                                type="text" 
                                name="name"
                                className="nuwa-search-input w-100"
                                placeholder="Name"
                                value={deviceInfo.name}
                                onChange={fieldSetter}
                            />
                        </div>

                        <span className="card-title lead"><b>Device Model</b></span>
                        <div className="nuwa-search-container mb-4" style={{padding: "10px"}}>
                            <input type="text" name="model" className="nuwa-search-input w-100" placeholder="Model" value={deviceInfo.model} onChange={fieldSetter} />
                        </div>

                        <span className="card-title lead"><b>Device Brand</b></span>
                        <div className="nuwa-search-container mb-4" style={{padding: "10px"}}>
                            <select name="brand" className="nuwa-search-input w-100" value={deviceInfo.brand} onChange={fieldSetter} >
                                <option value="">Select Brand</option>
                                <option value="android">Android</option>
                                <option value="iphone">IPhone</option>
                            </select>
                        </div>

                        <button className="nuwa-white-btn py-2 px-2 text-center w-75" onClick={addDevice}>
                            Add Device
                        </button>

                    </div>

                ) : (<DeviceSelector list={devices} type="selector" />)
            }


        </div>
    );
}

export default DeviceListScreen;