import React, { useContext } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AlertTypes from "../Enums/AlertTypes";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function NuwaAlert({type, message}: {type: string, message: any}) {

    const {alert, setShowAlert} = useContext(AlertContext);
    // const navigate = useNavigate();

    return (
        <div className="text-white nuwa-overlay-bg" style={{width: "100vw", height: "100vh", position: "fixed", top: 0, zIndex: 1000}}>
            <div className="nuwa-card p-3 w-75" style={{borderRadius: "20px", backdropFilter: "blur(35px)", position: "absolute", left: "50%", top: "50%", transform: "translateX(-50%) translateY(-50%)"}}>
                <div className="d-flex mb-3">
                    {(alert.type == AlertTypes.Success) && (<CheckCircleOutlineIcon sx={{fontSize: "50px", margin: "0 auto"}} />)}
                    {(alert.type == AlertTypes.Error) && (<HighlightOffIcon sx={{fontSize: "50px", margin: "0 auto"}} />)}
                    {(alert.type == AlertTypes.Info) && (<InfoIcon sx={{fontSize: "50px", margin: "0 auto"}} />)}
                    {(alert.type == AlertTypes.Warning) && (<WarningAmberIcon sx={{fontSize: "50px", margin: "0 auto"}} />)}
                </div>
                <h5 className="card-title text-center mb-2">{alert.message}</h5>
                <button className="nuwa-white-btn py-2 px-3 text-center w-100" onClick={() => {setShowAlert(false)}}>Ok</button>
            </div>
        </div>
    );
}

export default NuwaAlert;