
import FieldTypes from "../Enums/FiedTypes";
import Operators from "../Enums/Operators";
import IPagination from "../Interfaces/IPagination";
import { Authorized, normal } from "./api";

class MainAPI {

    public static async getAll(token: string, tableName: string, pageNumber: number, pageSize: number, condition?: any): Promise<IPagination<any>> {

        try {
            // let query = condition ? `?${Utils.objectToQueryString(condition)}` : "";
            // let query = "";
            return await Authorized(token).bodyRequest("post", `crud/getlist/${tableName}/${pageNumber}/${pageSize}`, condition);
        } catch (error: any) {
            console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }
    public static async getAllRelated(token: string, tableName: string, pageNumber: number, pageSize: number, condition?: any): Promise<IPagination<any>> {

        try {
            return await Authorized(token).bodyRequest("post", `crud/getlist/${tableName}/${pageNumber}/${pageSize}?type=related`, condition);
        } catch (error: any) {
            console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }
    
    public static async getAllRelatedFree(tableName: string, pageNumber: number, pageSize: number, condition?: any): Promise<IPagination<any>> {

        try {
            return await normal().bodyRequest("post", `crud/getlist/${tableName}/${pageNumber}/${pageSize}?type=related`, condition);
        } catch (error: any) {
            console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }

    public static async getAllFree(tableName: string, pageNumber: number, pageSize: number, condition?: any): Promise<IPagination<any>> {

        try {
            // let query = condition ? `?${Utils.objectToQueryString(condition)}` : "";
            // let query = "";
            return await normal().bodyRequest("post", `crud/getlist/${tableName}/${pageNumber}/${pageSize}`, condition);
        } catch (error: any) {
            console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }
    
    public static async loadAttachments(token: string, table: string, record_id: string): Promise<IPagination<any>> {

        try {
            // let query = condition ? `?${Utils.objectToQueryString(condition)}` : "";
            // let query = "";
            return await Authorized(token).bodyRequest("post", `crud/getlist/attachment/1/100`, {
                "record": {operator: Operators.IS, type: FieldTypes.TEXT, value: record_id},
                "table": {operator: Operators.IS, type: FieldTypes.TEXT, value: table}
            });
        } catch (error: any) {
            // console.log(error.message);
            return {
                Items: [],
                PageNumber: 1,
                PageSize: 10,
                TotalCount: 0
            };
        }
    }

    public static async getSingle(token: string, tableName: string, id: number): Promise<any> {
        try {
            return await Authorized(token).bodyRequest("get", `crud/getform/${tableName}/${id}`);
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    public static async getSingleRelated(token: string, tableName: string, id: number): Promise<any> {
        try {
            return await Authorized(token).bodyRequest("get", `crud/getform/${tableName}/${id}?type=related`);
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    public static async getSingleFree(tableName: string, id: number): Promise<any> {
        try {
            return await normal().bodyRequest("get", `crud/getform/${tableName}/${id}`);
        } catch (error: any) {
            console.log(error.message);
            return null;
        }
    }

    public static async createNew(token: string, table: string, new_data: any): Promise<any> {
        return await Authorized(token).bodyRequest("post", "crud/create", {tableName: table, data: new_data});
    }

    public static async createNewFree(table: string, new_data: any): Promise<any> {
        return await normal().bodyRequest("post", "crud/create", {tableName: table, data: new_data});
    }

    public static async update(token: string, tableName: string, new_data: any): Promise<any> {
        return await Authorized(token).bodyRequest("put", "crud/update", {table: tableName, data: new_data});
        // try {
        // } catch (error: any) {
        //     console.log(error.message);
        //     return null;
        // }
    }

    public static async updateFree(tableName: string, new_data: any): Promise<any> {
        return await normal().bodyRequest("put", "crud/update", {table: tableName, data: new_data});
    }

    // public static async forSelectBox(token: string){
    //     try {
    //         return await Authorized(token).bodyRequest("get", "cashiers/select_box");
    //     } catch (error: any) {
    //         console.log(error.message);
    //         return null;
    //     }
    // }
}

export default MainAPI;