import React from "react";

function Waiting() {

    return (
        <div className="text-white nuwa-overlay-bg" style={{width: "100vw", height: "100vh", position: "fixed", top: 0, zIndex: 1020}}>
            <div className="nuwa-card p-3 w-75" style={{borderRadius: "20px", height: "100px", backdropFilter: "blur(35px)", position: "absolute", left: "50%", top: "50%", transform: "translateX(-50%) translateY(-50%)"}}>
                <h5 className="card-title text-center mb-2">Almost Done</h5>
                <div className="loading_parent">
                    <div className="loading_child"></div>
                </div>
            </div>
        </div>
    );
}

export default Waiting;