import React, { useContext } from "react";
import AlertContext from "../Contexts/AlertContext";
import NavContext from "../Contexts/NavContext";
import AlertTypes from "../Enums/AlertTypes";
import { useNavigate } from "react-router-dom";

function IssueWarrantyForm() {

    const {alert, setShowAlert, openAlert} = useContext(AlertContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();

    return (
        <div className="w-100 text-white px-3">
            <h6 className="card-title" style={{fontSize: "22px"}}>Issue Warranty</h6>
            <p className="lead" style={{fontSize: "15px"}}>
                Your are trying to request warranty repair for the previous repair 
                Note: Ensure the bage on the phone is still there otherwise it will not be accepted.
            </p>
            <hr className="nuwa-line-hr" />

            <span className="card-title lead mb-2" style={{fontSize: "15px"}}><b>Problem Explanation</b></span>
            <div className="nuwa-search-container p-3 mb-3">
                <textarea className="nuwa-search-input w-100" placeholder="Explane the Problem" />
            </div>


            <button className="nuwa-white-btn py-3 px-3 text-center w-75"
                onClick={() => {
                    openAlert(AlertTypes.Success, "Your warranty request has been sent for review. we will contact you as soon as possible."); 
                    navigate("/order_detail");
                    selectNav && selectNav("order");
                }}
            >
                Issue Warranty
            </button>
        </div>
    );
}

export default IssueWarrantyForm;