import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NavContext from "../Contexts/NavContext";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import OrderStatus from "../Enums/OrderStatus";
import Utils from "../Models/Utils";
import Empty from "../Components/Empty";

function AccountScreen() {

    const {selectNav} = useContext(NavContext);
    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies, isLoggedIn, loggedUser } = useContext(AuthContext);
    
    const navigate = useNavigate();
    const params = useParams()

    const [ongoingOrders, setOngoingOrders] = useState<any[]>([]);
    const [previousOrders, setPreviousOrders] = useState<any[]>([]);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        if(isLoggedIn) {
            loadService();
        }else {
            openAlert(AlertTypes.Warning, "You are currently in Guest mode. You have to login or order a service to see account information!")
            navigate(`/home`);
            (selectNav && selectNav("home"));
        }
    }, [])

    const loadService = async () => {
        setTimeout(() => {setWaiting(true);}, 1);
        try{
            let service_response = await MainAPI.getAllRelated(cookies.login_token, "order", 1, 100, {
                userId: {
                    type: "number",
                    operator: Operators.IS,
                    value: loggedUser.Id
                }
            });
            // console.log("checking", [OrderStatus.Approved, OrderStatus.Requested, OrderStatus.Finished, OrderStatus.Ongoing, OrderStatus.Paid, OrderStatus.Received].includes(service_response.Items[0].state));
            setPreviousOrders(service_response.Items.filter(ord => ([OrderStatus.Taken, OrderStatus.Closed, OrderStatus.Cancelled].includes(ord.state))));
            setOngoingOrders(service_response.Items.filter(ord => ([OrderStatus.Approved, OrderStatus.Requested, OrderStatus.Finished, OrderStatus.Ongoing, OrderStatus.Paid, OrderStatus.Received].includes(ord.state))));
        }catch(error: any){
            openAlert(AlertTypes.Error, error.message);
        }
        setTimeout(() => {setWaiting(false);}, 1);
    }

    return (
        <div className="text-white h-100 w-100 px-3 justify-content-center" style={{display: "flex", flexDirection: 'column'}}>
            
            <div className="d-flex justify-content-center mb-3">
                <img src="./images/user_avatar_white.png" alt="profile" className="rounded-circle" style={{width: "146px", height: "auto"}} />
            </div>
            <h4 className="card-title text-center">{(isLoggedIn && loggedUser ? loggedUser.FullName : "Guest User")}</h4>

            <hr className="nuwa-line-hr" />

            <div className="w-100 h-100" style={{overflow: "hidden auto"}}>

                <h5 className="card-title mb-3" style={{fontSize: "18px"}}>On Going Services</h5>

                <p className="lead" style={{fontSize: "15px"}}>
                    This horizontaly listed items are currently ongoing orders you can select and view the details and take necessary actions!
                </p>

                <div className="w-100" style={{overflow: "auto hidden"}} >
                    {
                        (ongoingOrders.length > 0) ? (
                            <div className="d-flex" style={{width: "max-content"}}>
                                
                                {

                                    ongoingOrders.map(ord => (
                                        <div key={ord.id} className="nuwa-card p-3 me-3"
                                            style={{borderRadius: "13px", width: "17rem", backdropFilter: "blur(35px)", cursor: "pointer"}}
                                        >
                                            <h6 className="card-title mb-3" style={{fontSize: "17px"}}>{ord.service.title}</h6>
                                            <div className="d-flex align-items-center" style={{color: "#939393"}}>
                                                <PhoneIphoneIcon className="nuwa-icon me-3" style={{fontSize: "20px"}}/>
                                                <div className="card-subtitle" style={{fontSize: "14px"}}>{ord.device.brand}, {ord.device.model}</div>
                                            </div>
                                            <div className="d-flex align-items-center" style={{color: "#939393"}}>
                                                <PermDeviceInformationIcon className="nuwa-icon me-3" style={{fontSize: "20px"}}/>
                                                <div className="card-subtitle" style={{fontSize: "14px"}}>{ord.state}</div>
                                            </div>
                                            <div className="d-flex align-items-center mb-2" style={{color: "#939393"}}>
                                                <CalendarMonthIcon className="nuwa-icon me-3" style={{fontSize: "20px"}}/>
                                                <div className="card-subtitle" style={{fontSize: "14px"}}>{Utils.convertISOToDate(ord.date)}</div>
                                            </div>
                                            <button
                                                className="btn btn-light btn-sm w-100"
                                                onClick={() => {
                                                    navigate(`/Order_detail/${ord.id}`);
                                                    (selectNav && selectNav("order"))
                                                }}
                                            >
                                                Open
                                            </button>
                                        </div>
                                    ))

                                }

                            </div>
                        ) : (<Empty text="No Ongoing Order" />)
                    }
                </div>

                <hr className="nuwa-line-hr" />

                <h5 className="card-title mb-3" style={{fontSize: "18px"}}>Previous Orders</h5>

                <div className="w-100">
                    
                    {
                        (previousOrders.length > 0) ? (

                            previousOrders.map(ord => {
                                let order_date = new Date(ord.date);
                                let order_day = order_date.getDate();
                                let order_month = order_date.getMonth();
                                return (
                                    <div key={ord.id} className="nuwa-card d-flex mb-3" style={{borderRadius: "13px", backdropFilter: "blur(35px)", overflow: "hidden", cursor: "pointer"}} onClick={() => {
                                        navigate(`/Order_detail/${ord.id}`);
                                        (selectNav && selectNav("order"))
                                    }}>
                                        <div className="history_date_container justify-content-center" style={{display: "flex", flexDirection: "column"}}>
                                            <div className="w-100">
                                                <h6 style={{fontSize: "22px"}} className="card-title text-center pb-0 mb-0">{order_day}</h6>
                                                <div style={{fontSize: "8px"}} className="card-subtitle text-center mt-0 pt-0">{months[order_month]}</div>
                                            </div>
                                        </div>
                                        <div className="w-100 px-3 py-2">
                                            <div className="d-flex mb-1 align-items-center justify-content-between">
                                                <h6 className="card-title" style={{fontSize: "13px"}}>{ord.service.title}</h6>
                                                <div className="nuwa-card rounded px-2">
                                                    <span style={{fontSize: "11px", fontWeight: "bolder"}}>{ord.price} ETB</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center" style={{color: "#939393"}}>
                                                <PhoneIphoneIcon className="nuwa-icon me-2" style={{fontSize: "12px"}}/>
                                                <div className="card-subtitle" style={{fontSize: "8px"}}>{ord.device.name}, {ord.device.model}</div>
                                            </div>
                                            <div className="d-flex align-items-center" style={{color: "#939393"}}>
                                                <PermDeviceInformationIcon className="nuwa-icon me-2" style={{fontSize: "12px"}}/>
                                                <div className="card-subtitle" style={{fontSize: "8px"}}>{ord.state}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (<Empty text="No Previous Order" />)
                    }

                </div>

            </div>

        </div>
    );
}

export default AccountScreen;