import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import { props } from "../APIs/api";
import Utils from "../Models/Utils";
import StateContext from "../Contexts/StateContext";

function BlogDetailScreen() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { Blogs } = useContext(StateContext);
    const params = useParams()

    const [blog, setBlog] = useState<any>(null);

    useEffect(() => {
        loadBlog();
    }, [])

    const loadBlog = async () => {
        let found_blog = Blogs.find(dv => (dv.id == parseInt(params?.id ?? "0")));
        setBlog(found_blog ?? null);
    }

    return (
        (blog) && (
            <div className="text-white w-100 px-3">

                <img src={`${props.baseURL}file/${blog.image}`} alt="blog" className="w-100 mb-2" style={{borderRadius: "20px"}} />
                <h4 className="card-title" style={{fontSize: "25px"}}>{blog.title}</h4>
                <p className="card-subtitle w-100">{Utils.convertISOToDate(blog.date)}</p>
                <hr className="nuwa-line-hr" />
                <p className="card-subtitle w-100" style={{whiteSpace: "pre-wrap"}}>{blog.body}</p>

            </div>
        )
    );
}

export default BlogDetailScreen;