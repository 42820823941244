import React, { useContext } from "react";
import AlertContext from "../Contexts/AlertContext";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function NotSupported() {

    const {alert, setShowAlert} = useContext(AlertContext);
    // const navigate = useNavigate();

    return (
        <div className="text-white nuwa-overlay-bg" style={{width: "100vw", height: "100vh", position: "fixed", top: 0, zIndex: 1020}}>
            <div className="nuwa-card p-3 w-75" style={{borderRadius: "20px", backdropFilter: "blur(35px)", position: "absolute", left: "50%", top: "50%", transform: "translateX(-50%) translateY(-50%)"}}>
                <div className="d-flex mb-3">
                    <HighlightOffIcon sx={{fontSize: "50px", margin: "0 auto"}} />
                </div>
                <h5 className="card-title text-center mb-2">Not supported on Desktop Browsers</h5>
            </div>
        </div>
    );
}

export default NotSupported;