import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainAPI from "../APIs/MainAPI";
import AlertContext from "../Contexts/AlertContext";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import { props } from "../APIs/api";
import StateContext from "../Contexts/StateContext";

function ServiceDetail() {

    const navigate = useNavigate();
    const {alert, setShowAlert, openAlert, setWaiting, updateCurrentOrder, currentOrder} = useContext(AlertContext);
    const { cookies } = useContext(AuthContext);
    const {Services} = useContext(StateContext);

    const params = useParams()

    const [service, setService] = useState<any>(null);

    useEffect(() => {
        loadService();
    }, [Services])

    const loadService = async () => {

        setService(Services.find(sv => (sv.id == parseInt(params?.id ?? "0"))) ?? null);
        // setTimeout(() => {setWaiting(true);}, 1);
        // try{
        //     let service_response = await MainAPI.getSingleFree("service", parseInt(params?.id ?? "0"));
        //     setService(service_response);
        // }catch(error: any){
        //     openAlert(AlertTypes.Error, error.message);
        // }
        // setTimeout(() => {setWaiting(false);}, 1);
    }
    return (service) && (
        <div className="w-100 text-white px-3">
            <div className="d-flex mt-3">
                <img src={`${props.baseURL}file/${service.picture}`} alt="user pic" style={{width: "50px", height: "50px"}}/>
                <div className="w-100 ms-3">
                    <h5 className="card-title">{service.title}</h5>
                    <span className="card-subtitle">{service.price} Engineers</span>
                </div>
            </div>
            <hr className="nuwa-line-hr" />
            <p className="lead">{service.description}</p>
            <button className="nuwa-white-btn py-2 px-2 text-center w-75" onClick={() => {
                updateCurrentOrder({...currentOrder, service: service});
                navigate("/select_device");
            }}>
                Order Service
            </button>
        </div>
    );
}

export default ServiceDetail;