import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';


function NuwaAvatar({userName}: {userName: string}) {

    const [displayName, setDisplayName] = useState("Unknown User");

    useEffect(() => {
        setDisplayName(userName != "" ? userName : "Unknown");
    }, [userName]);

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;
        
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        let color = '#';
        
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        
        return color;

    }

    const stringAvatar = (name: string) => {
        let decoded = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${decoded[0][0].toUpperCase()}${(decoded[1] && decoded[1] != "") ? decoded[1][0] : ""}`,
        };
    }

    return (
        <Avatar {...stringAvatar(displayName)} />
    );

}

export default NuwaAvatar;