import { SystemSecurityUpdateWarning } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainAPI from "../APIs/MainAPI";
import AlertContext from "../Contexts/AlertContext";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import { props } from "../APIs/api";
import NavContext from "../Contexts/NavContext";
import StateContext from "../Contexts/StateContext";

function ServicesScreen() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();
    const params = useParams()

    const [devices, setDevices] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");
    const {Services} = useContext(StateContext);


    useEffect(() => {
        // loadServices();
    }, [])

    const loadServices = async () => {

        // setTimeout(() => {setWaiting(true);}, 1);
        // try{
        //     let service_response = await MainAPI.getAllFree("service", 1, 100);
        //     setDevices(service_response.Items);
        //     setDevices(service_response.Items);
        // }catch(error: any){
        //     openAlert(AlertTypes.Error, error.message);
        // }
        // setTimeout(() => {setWaiting(false);}, 1);

        // setServices()
    }

    return (devices) && (
        <div className="w-100 text-white">
            <div className="px-3">
                <h5 className="card-title">Pick Service</h5>
                <p className="mb-3">
                    You can pick one out of our wide selection of service options and take your Computer in for our Best price. 
                </p>
            </div>
            <div className="row px-0 m-0 justify-content-start">
                {
                    Services.map(srvs => (
                        <div key={srvs.id} className="col-4 mb-3 d-flex" >
                            <div className="nuwa-service-big-card p-3 mx-auto" onClick={() => {navigate(`/service_detail/${srvs.id}`);  (selectNav && selectNav("order"))}}>
                                <div className="d-flex">
                                    <img src={`${props.baseURL}file/${srvs.picture}`} className="mx-auto" style={{width: "30px"}} alt="service icon" />
                                </div>
                                <h6 className="card-title text-center" style={{fontSize: "14px", textWrap: "wrap", wordBreak: 'break-word'}}>{srvs.title}</h6>
                            </div>
                        </div>
                    ))
                }
                
            </div>
        </div>
    );
}

export default ServicesScreen;