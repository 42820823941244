import React, { useContext, useEffect, useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import NavContext from "../Contexts/NavContext";
import { useNavigate } from "react-router-dom";


function SideNavBar() {

    const { items } = useContext(NavContext);
    
    const navigate = useNavigate();
    // const [navItems, setNavItems] = useState<{icon: any, id: string, selected: boolean}[]>([]);

    // useEffect(() => {
    //     setNavItems(ni => items);
    // }, [items]);

    return (
        <div className="nuwa-side-nav px-3 py-4">
            {
                items.map(itm => (
                    <div 
                        className={`nuwa-side-nav-item d-flex align-items-center ${itm.selected ? "side_item_selected" : ""} mb-4 px-3 py-2`} 
                        onClick={() => {
                            itm.onSelect();
                            navigate(`/${itm.id}`);
                        }}
                    >
                        <itm.icon className="nuwa-side-icon" />
                        <span className="nuwa-side-item-text card-title ms-3">{itm.label}</span>
                    </div>
                ))
            }
            
            <div className="p-0 w-100" style={{position: "relative", left: 0, bottom: 0}}>
                <div className="nuwa-side-nav-item px-3 py-2">
                    <img src="./images/user_image.png" style={{width: "35px", height: "35px"}} alt="user" />
                    <span className="nuwa-side-item-text card-title ms-3">Pitter Jason</span>
                </div>
            </div>
        </div>
    );
}

export default SideNavBar;