import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import AlertTypes from "../Enums/AlertTypes";
import Operators from "../Enums/Operators";
import Empty from "../Components/Empty";
import Utils from "../Models/Utils";
import SearchForm from "../Components/SearchForm";
import StateContext from "../Contexts/StateContext";

function FAQScreen() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies } = useContext(AuthContext);
    const { FAQs } = useContext(StateContext);

    const navigate = useNavigate();
    const params = useParams()

    const [faq, setFaq] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");


    useEffect(() => {
        loadFAQ();
    }, [searchWord])

    const loadFAQ = async () => {
        setFaq(
            searchWord == "" ?
            FAQs : FAQs.filter(faq => (faq.title.includes(searchWord)))
        );
    }

    return (
        <div className="text-white w-100 px-3" >            
        
            <h4 className="card-title text-center" style={{fontSize: "30px"}}>FAQ</h4>
            <hr className="nuwa-line-hr" />
            <div className="px-3 mb-3">
                <SearchForm onSubmit={(word: string) => {setSearchWord(word)}} />
            </div>
            <hr className="nuwa-line-hr" />

            {
                (faq.length > 0) ? (
                    faq.map(fq => (
                        <div className="nuwa-card p-3 mb-2" style={{borderRadius: "13px", backdropFilter: "blur(35px)", fontSize: "17px"}}>
                            <h4 className="card-title mb-0" style={{fontSize: "20px"}}>{fq.title}</h4>
                            <span className="card-subtitle" style={{fontSize: "11px"}}>{Utils.convertISOToDate(fq.date)}</span>
                            <p className="card-subtitle" style={{fontSize: "13px"}}>{fq.body}</p>
                        </div>
                    ))
                ) : (<Empty text="No Record Found" />)
            }

        </div>
    );
}

export default FAQScreen;