import { colors, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { transform } from "typescript";
import MenuIcon from '@mui/icons-material/Menu';
import NavContext from "../Contexts/NavContext";
import {isMobile, isDesktop, isTablet} from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import history from "history";

function TopNavBar() {

    const { items, selectedNav, selectNav } = useContext(NavContext);
    // const { items } = useContext(NavContext);
    
    const navigate = useNavigate();
    const location = useLocation();

    const [menuVisible, setMenuVisible] = useState(false);
    const [path, setPath] = useState<string>("/");

    const toggleVisible = () => {
        setMenuVisible(!menuVisible);
        // console.log("menue working");
    }

    useEffect(() => {

        setPath(location.pathname);

    }, [location])

    return (
        <div className="d-flex justify-content-between align-items-center" style={{height: "max-content", position: "relative"}} >

            {(isMobile && (path == "/" || path == "/home" || selectedNav?.id == "home")) ? (
                <IconButton aria-label="menu" onClick={toggleVisible} onBlur={() => {
                    setTimeout(() => {
                        setMenuVisible(false);
                    }, 10);
                }}>
                    <MenuIcon sx={{color: colors.common.white}} />
                </IconButton>
            ) : (
                <IconButton aria-label="back" onClick={() => {window.history.back();}}>
                    <ArrowBackIcon sx={{color: colors.common.white}} />
                </IconButton>
            )}
            <span style={{fontSize: "15px"}} className="text-white card-title">Nuwa Mobile Clinic</span>
            <div style={{width: "30px", height: "30px"}}>
                <img src="/images/logo_white.svg" alt="logo" style={{width: "200%", position: "relative", left: "50%", top: "50%", transform: "translateX(-50%) translateY(-50%)"}} />
            </div>
            {(isMobile && menuVisible) && (
                <div className="nuwa-mobile-menu">
                    <div className="nuwa-menu-item px-3 py-2" onClick={() => {navigate("/blogs"); (selectNav && selectNav("blog"));}}> Blog </div>
                    <div className="nuwa-menu-item px-3 py-2" onClick={() => {navigate("/faq"); (selectNav && selectNav("faq"));} }> FAQs </div>
                    <div className="nuwa-menu-item px-3 py-2" onClick={() => {navigate("/about_us"); (selectNav && selectNav("about_us"));} }> About Us </div>
                </div>
            )}
        </div>
    );
}

export default TopNavBar;