import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import MainScreen from './Screens/MainScreen';
import HomeIcon from '@mui/icons-material/Home';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DevicesIcon from '@mui/icons-material/Devices';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import INavItem from './Interfaces/INavItem';
import NavContext from './Contexts/NavContext';
import Home from './Screens/Home';
import ChatScreen from './Screens/Chat';
import ServicesScreen from './Screens/Services';
import ServicesDetailScreen from './Screens/ServiceDetail';
import DevicesScreen from './Screens/Devices';
import OrderFormScreen from './Screens/OrderForm';
import AlertComponent from './Components/Alert';
import AlertTypes from './Enums/AlertTypes';
import AlertContext from './Contexts/AlertContext';
import BlogsScreen from './Screens/Blogs';
import BlogDetailScreen from './Screens/BlogDetail';
import DeviceDetailScreen from './Screens/DeviceDetail';
import DeviceListScreen from './Screens/DeviceList';
import PaymentScreen from './Screens/Payment';
import TrackOrderScreen from './Screens/TrackOrder';
import AccountScreen from './Screens/AccountDetail';
import OrderDetailScreen from './Screens/OrderDetail';
import RepairDetailScreen from './Screens/RepairDetail';
import RateOrderScreen from './Screens/RateOrder';
import IssueWarrantyForm from './Screens/IssueWarrantyForm';
import FAQScreen from './Screens/FAQList';
import AboutUsScreen from './Screens/AboutUs';
import { isMobile } from 'react-device-detect';
import NotSupported from './Components/NotSupported';
import { information } from './APIs/AuthAPI';
import Waiting from './Components/Waiting';
import AuthContext from './Contexts/AuthContext';
import IServerResponse from './Interfaces/IServerResponse';
import SocketContext from './Contexts/SocketContext';
import MainAPI from './APIs/MainAPI';
import StateContext from './Contexts/StateContext';
import SearchService from './Screens/ServiceSearch';
import OnboardingScreen from './Screens/Onboarding';

function App() {

	const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
	const [loggedUser, setLoggedUser] = useState<any>(null);
	const [cookies, setCookie, removeCookie] = useCookies(["login_token", "chat_id"]);
	const [authWaiting, setAuthWaiting] = useState<boolean>(false);
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [showWaiting, setWaiting] = useState<boolean>(false);

    const [serverMessage, setServerMessage] = useState<any>(null);
    const [isServerReady, setIsServerReady] = useState<boolean>(false);
    const [socketServer, setSocketServer] = useState<WebSocket | null>(null);
    const [chatInfo, setChatInfo] = useState<any>(null);
    const [chatMessage, setChatMessage] = useState<string>("");
    const [onboarding, setOnboarding] = useState<boolean>(false);

	const [alert, setAlert] = useState<{message: string, type: AlertTypes}>({
        message: "",
        type: AlertTypes.Warning
    });
	const [menu, setMenu] = useState<boolean>(false);
    const [selectedNav, setSelectedNav] = useState<INavItem>({
        icon: HomeIcon,
        id: "home",
        label: "Home",
        selected: true,
        onSelect: () => {selectNav("home")}
    });
	const [navItems, setNavItems] = useState<INavItem[]>([
		{
			icon: HomeIcon,
			id: "home",
			label: "Home",
			selected: true,
			onSelect: () => {selectNav("home")}
		},
		{
			icon: PlaylistAddCheckIcon,
			id: "order",
			label: "Services",
			selected: false,
			onSelect: () => {selectNav("order")}
		},
		{
			icon: DevicesIcon,
			id: "devices",
			label: "Devices",
			selected: false,
			onSelect: () => {selectNav("devices")}
		},
		{
			icon: ChatRoundedIcon,
			id: "chat",
			label: "Contact Us",
			selected: false,
			onSelect: () => {selectNav("chat")}
		}
	]);

	const [devices, setDevices] = useState<any[]>([]);
	const [services, setServices] = useState<any[]>([]);
	const [blogs, setBlogs] = useState<any[]>([]);
	const [faqs, setFAQs] = useState<any[]>([]);
	const [localData, setLocalData] = useState<any>({});

    const [currentOrder, setCurrentOrder] = useState<any>({
        service: null,
        device: null
    });

    useEffect(() => {

        const checkAuth = async (token: string) => {

            setTimeout(() => { setAuthWaiting(true); }, 1);
            let response = await information(token);
            setLoggedIn(response.status);
            setLoggedUser(response.data);
            setAuthWaiting(false);

        };

        if (cookies.login_token && cookies.login_token != "") {
            checkAuth(cookies.login_token);
        }

        loadstate();

    }, []);

    useEffect(() => {

        connectWithServer();
        // if(loggedUser && !socketServer) {
        // }else if(!loggedUser && socketServer) {
        //     socketServer.close();
        //     setSocketServer(null);
        // }

        // setLocalData(ld => ({
        //     ...ld,
        //     Cashiers: [
        //         {
        //             value: loggedUser?.Id,
        //             label: loggedUser?.FullName
        //         }
        //     ]
        // }));

    }, [loggedUser]);

    const loadstate = async () => {
        // let temp_data = localData;
        try{
            let serviceResponse = await MainAPI.getAllFree("service", 1, 100);
            let deviceResponse = (await MainAPI.getAllFree("device", 1, 100));
            let blogsResponse = (await MainAPI.getAllFree("blog", 1, 100));
            let faqsResponse = (await MainAPI.getAllFree("faq", 1, 100));
            setServices(serviceResponse.Items);
            setDevices(deviceResponse.Items);
            setBlogs(blogsResponse.Items);
            setFAQs(faqsResponse.Items);
        } catch(error: any) {
            console.log(error.message);
            openAlert(AlertTypes.Error, "Error happened on the system, We are Working on it!");
        }
    }

    const connectWithServer = () => {
        // const socket = new WebSocket("ws://localhost:7000")
        // const socket = new WebSocket("ws://192.168.0.3:7000")
        // const socket = new WebSocket("wss://24.144.96.108:7000")
        const socket = new WebSocket("wss://nuwamobile.com:7000")

        socket.onopen = () => {

            setIsServerReady(true);
            let send_data: any = {
                status: "online"
            };
            if(loggedUser || cookies.login_token) {
                send_data.token = cookies.login_token;
            } else if(cookies.chat_id) {
                send_data.chatId = cookies.chat_id;
            }

            socket.send(JSON.stringify(send_data));

        };

        socket.onclose = () => setIsServerReady(false);
        socket.onmessage = (event) => receiveServerMessage(event.data);

        setSocketServer(socket);

    }

    const sendServerRequest = (message: any) => {
        socketServer?.send(JSON.stringify(message));
    }

    const receiveServerMessage = (msg: string) => {

        // {"status":"game_start","data":{"BranchId":1,"StartDatetime":1702012764,"CompanyId":2,"IsFinished":1,"Id":381}}
        // {"status":"presence","data":{"elapsedTime":1}}

        let message: IServerResponse = JSON.parse(msg);

        

        if(message.status == "online") {

            setChatInfo((ci: any) => message.data);
            // if(!loggedUser && (!cookies.login_token || cookies.login_token == "")) {
            // }

            if(!loggedUser && (!cookies.login_token || cookies.login_token == "") && cookies.chat_id != message.data.Id) {
                //cookies.chat_id != message.data.Id
                setCookie("chat_id", message.data.Id, { path: "/" });
            }
        } else if(message.status == "incoming") {
            setChatMessage(message.data.message);
            setServerMessage(message.data);
        } else if(message.status == "error") {
            openAlert(AlertTypes.Error, message.data)
        }

        setServerMessage(msg);
        // console.log(msg);

    }

	const selectNav = (name: string) => {
        setSelectedNav(sni => ({...sni, id: name}));
		let temp_items = navItems.map(itm => {
            
            if(itm.id == name) {
                itm.selected = true;
                setSelectedNav(itm);
            }

            return {
                ...itm,
                selected: (itm.id == name)
            };

        });
		setNavItems(tm => temp_items);

	}

    const openAlert = (type: AlertTypes, message: string) => {

        setAlert({message, type});
        setShowAlert(true);
        // setTimeout(() => {setShowAlert(false);}, 4000);

    }

    const updateCurrentOrder = (current_order: any) => {
        setCurrentOrder(current_order);
    };

    return (isMobile) ? (
        <StateContext.Provider value={{Services: services, Devices: devices, FAQs: faqs, Blogs: blogs}} >
            <SocketContext.Provider value={{ isReady: isServerReady, server: socketServer, chatMessage, serverMessage, chatInfo, sendMessage: sendServerRequest }} >
                <NavContext.Provider value={{items: navItems, selectedNav, selectNav}}>
                    <AlertContext.Provider value={{showAlert, alert: alert, setShowAlert, openAlert, setWaiting, currentOrder, updateCurrentOrder, onboarding, setOnboarding}}>
                        <AuthContext.Provider value={{
                            isLoggedIn, loggedUser, setLoggedUser, setLoggedIn, setCookie, cookies, removeCookie, authWaiting, localData
                        }}>
                            <BrowserRouter>
                                {
                                    !authWaiting && (
                                        <Routes>
                                            <Route path="/" element={<MainScreen />} >
                                                <Route index element={<Home />} />
                                                <Route path="home" element={<Home />} />
                                                <Route path="order" element={<ServicesScreen />} />
                                                <Route path="service_detail/:id" element={<ServicesDetailScreen />} />
                                                <Route path="order_form" element={<OrderFormScreen />} />
                                                <Route path="chat" element={<ChatScreen />} />
                                                <Route path="devices" element={<DevicesScreen />} />
                                                <Route path="select_device" element={<DeviceListScreen />} />
                                                <Route path="blogs" element={<BlogsScreen />} />
                                                <Route path="blog_detail/:id" element={<BlogDetailScreen />} />
                                                <Route path="device_detail/:id" element={<DeviceDetailScreen />} />
                                                <Route path="payment" element={<PaymentScreen />} />
                                                <Route path="track_order" element={<TrackOrderScreen />} />
                                                <Route path="account" element={<AccountScreen />} />
                                                <Route path="order_detail/:id" element={<OrderDetailScreen />} />
                                                <Route path="repair_detail/:id" element={<RepairDetailScreen />} />
                                                <Route path="service_search" element={<SearchService />} />

                                                <Route path="about_us" element={<AboutUsScreen />} />
                                                <Route path="issue_warranty" element={<IssueWarrantyForm />} />
                                                <Route path="faq" element={<FAQScreen />} />
                                                <Route path="rate_order" element={<RateOrderScreen />} />
                                            </Route>
                                        </Routes>
                                    )
                                }
                                {showWaiting ? (<Waiting />) : ""}
                                {showAlert ? (<AlertComponent message={alert.message} type={alert.type} />) : ""}
                                {onboarding ? (<OnboardingScreen />) : ""}
                            </BrowserRouter>
                        </AuthContext.Provider>
                    </AlertContext.Provider>
                </NavContext.Provider>
            </SocketContext.Provider>
        </StateContext.Provider>
    ) : (<NotSupported />);
}

export default App;
