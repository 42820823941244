import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import SearchForm from "../Components/SearchForm";
import DeviceSelector from "../Components/DeviceSelector";
import AlertTypes from "../Enums/AlertTypes";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import Operators from "../Enums/Operators";
import StateContext from "../Contexts/StateContext";
import NavContext from "../Contexts/NavContext";
import { props } from "../APIs/api";

function SearchService() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const {Services} = useContext(StateContext);
    const { cookies } = useContext(AuthContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();
    
    const [devices, setDevices] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");


    useEffect(() => {
        loadService();
    }, [searchWord, Services])

    const loadService = async () => {
        setDevices(Services.filter(dv => (dv.title.includes(searchWord))));
    }

    return (
        <div className="text-white h-100 w-100 pt-3" style={{display: "flex", flexDirection: 'column'}}>
            <div className="px-3 mb-3">
                <SearchForm onSubmit={(word: string) => { setSearchWord(word); }} />
            </div>
            <div className="text-white h-100 w-100 px-3 " style={{overflow: "hidden auto"}}>
                {
                    devices.map(dvs => (
                        <div key={dvs.id} className="nuwa-card p-2 w-100 d-flex mb-3" 
                            style={{borderRadius: "13px", backdropFilter: "blur(35px)", cursor: "pointer"}}
                            onClick={() => {
                                navigate(`/service_detail/${dvs.id}`); (selectNav && selectNav("service"))
                            }}
                        >
                            <img src={`${props.baseURL}file/${dvs.picture}`} alt="phone pic" className="rounded-2" style={{width: "25%"}} />
                            <div className="w-100 ms-3">
                                <h5 className="card-title" style={{fontSize: "18px"}}>{dvs.title}</h5>
                                <span className="lead text-center" style={{fontSize: "11px"}} >{dvs.description}</span>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default SearchService;