import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import { props } from "../APIs/api";
import NavContext from "../Contexts/NavContext";

function DeviceSelector({list, type}: {list: any[], type: ("selector"|"view")}) {

    const {alert, setShowAlert, updateCurrentOrder, currentOrder} = useContext(AlertContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();

    const [devices, setDevices] = useState<any[]>([]);

    useEffect(() => {
        setDevices(list);
    }, [list]);

    return (
        <div className="text-white h-100 w-100 px-3" style={{overflow: "hidden auto"}}>
            
            {
                devices.map(dvs => (
                    <div key={dvs.id} className="nuwa-card p-2 w-100 d-flex mb-3" 
                        style={{borderRadius: "13px", backdropFilter: "blur(35px)", cursor: "pointer"}}
                        onClick={() => {
                            if(type == "view") {
                                navigate(`/device_detail/${dvs.id}`); (selectNav && selectNav("device"))
                            } else {
                                updateCurrentOrder({...currentOrder, device: dvs});
                                navigate(`/order_form`);  (selectNav && selectNav("order"))
                            }
                        }}
                    >
                        <img src={`${props.baseURL}file/${dvs.picture}`} alt="phone pic" className="rounded-2" style={{width: "25%"}} />
                        <div className="w-100 ms-3">
                            <h5 className="card-title" style={{fontSize: "18px"}}>{dvs.name}</h5>
                            <span className="lead text-center" style={{fontSize: "11px"}} >{dvs.model}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default DeviceSelector;