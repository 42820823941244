import {createContext} from 'react';
import AlertTypes from '../Enums/AlertTypes';

export default createContext<{
    showAlert: boolean,
    alert: {type: AlertTypes, message: string},
    setShowAlert: any,
    openAlert: any,
    setWaiting: any,
    updateCurrentOrder: any,
    currentOrder: any,
    onboarding: boolean,
    setOnboarding: any
}>({
    showAlert: false,
    alert: {
        type: AlertTypes.Success,
        message: ""
    },
    setShowAlert: null,
    openAlert: null,
    updateCurrentOrder: null,
    currentOrder: null,
    setWaiting: null,
    onboarding: false,
    setOnboarding: null

});