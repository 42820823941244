import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../Contexts/AlertContext";
import AlertTypes from "../Enums/AlertTypes";
import SearchForm from "../Components/SearchForm";
import MainAPI from "../APIs/MainAPI";
import AuthContext from "../Contexts/AuthContext";
import Operators from "../Enums/Operators";
import Utils from "../Models/Utils";
import { props } from "../APIs/api";
import NavContext from "../Contexts/NavContext";
import Empty from "../Components/Empty";
import StateContext from "../Contexts/StateContext";

function BlogsScreen() {

    const {alert, setShowAlert, openAlert, setWaiting} = useContext(AlertContext);
    const { cookies } = useContext(AuthContext);
    const { Blogs } = useContext(StateContext);
    const {selectNav} = useContext(NavContext);

    const navigate = useNavigate();

    const [blogs, setBlog] = useState<any[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");

    useEffect(() => {
        loadBlogs();
    }, [searchWord])

    const loadBlogs = async () => {
        // setTimeout(() => {setWaiting(true);}, 1);
        // try{
        //     let blog_response = await MainAPI.getAllFree("blog", 1, 100, {
        //         title: {
        //             type: "text",
        //             operator: Operators.CONTAINS,
        //             value: searchWord
        //         }
        //     });
        //     setBlog(blog_response.Items);
        // }catch(error: any){
        //     openAlert(AlertTypes.Error, error.message);
        // }
        // setTimeout(() => {setWaiting(false);}, 1);
        setBlog(searchWord == "" ? Blogs : Blogs.filter(faq => (faq.title.includes(searchWord))));
    }

    return (
        <div className="text-white w-100 h-100" style={{display: "flex", flexDirection: 'column'}}>
            <div className="px-3 mb-3">
                <SearchForm onSubmit={(word: string) => {console.log(word); setSearchWord(word)}} />
            </div>
            <div className="w-100 h-100 px-3" style={{overflow: "hidden auto"}}>

                {
                    blogs.length > 0 ? (
                        blogs.map(blg => (
                            <div key={blg.id} className="nuwa-blog-container mb-3" style={{width: "100%"}} onClick={() => {navigate(`/blog_detail/${blg.id}`); selectNav && selectNav("blog")}}>
                                <img src={`${props.baseURL}file/${blg.image}`} alt="blog" className="w-100 mb-2" style={{borderRadius: "20px"}} />
                                <h5 className="card-title" style={{fontSize: "15px"}}>{blg.title}</h5>
                                <p className="card-subtitle w-100" style={{right: "0", fontSize: "12px"}}>{Utils.convertISOToDate(blg.date)}</p>
                            </div>
                        ))
                    ) : (<Empty text="No blogs Found" />)
                }
                
            </div>

        </div>
    );
}

export default BlogsScreen;